import CONFIG from '@root/config'
import client from '@/api/Client'
import { mapActions } from 'vuex'
import eventBus from '@/plugins/eventBus'
import moment from 'moment'

function beautify (value) {
    if (!value) {
        return value
    }
    if (Array.isArray(value)) {
        return value.map(singleValue => beautify(singleValue))
    }
    value = value.toString()

    return value.toString().charAt(0).toUpperCase() + value.slice(1).replace(/_/g, ' ')
}

export default {
    install (Vue, options) {
        this.$vue = Vue
        this.$options = options

        moment.locale('en-gb')

        Vue.config.productionTip = false
        Vue.prototype.$bus = eventBus
        Vue.prototype.$api = client
            .withAccessTokenReceiver((accessToken) => {
                this.$options.store.dispatch('auth/updateAuth', { token: accessToken })
            })
            .withErrorInterceptor((error) => {
                if (CONFIG.DEBUG) {
                    console.error(error)
                }

                if (error.response && error.response.status && error.response.status === 401) {
                    eventBus.$emit('userUnauthorized')
                }

                if (error.response) {
                    return Promise.reject(error.response)
                }
                return Promise.reject(error)
            })

        this.initFilters()
        this.initDirectives()
        this.initPlugins()
        this.initMixins()
        this.guard()
        this.$options.router.afterEach(to => {
            eventBus.$emit('route-updated', arguments)
        })
    },

    guard () {
        this.$options.router.beforeEach((to, from, next) => {
            let unguarded = to.matched.some(record => record.meta.unguarded)

            if (!unguarded) {
                this.$options.store.dispatch('auth/check')
                this.$options.store.dispatch('auth/guard', { to, from, next })
            }
            const supplierUnguarded = to.matched.some(record => record.meta.supplierUnguarded)
            const isSupplier = this.$options && this.$options.store && this.$options.store.state && this.$options.store.state.auth && this.$options.store.state.auth.user && this.$options.store.state.auth.user.supplierType === 'SUPPLIER'
            const viewingSupplierHomeRoute = !unguarded && to.name === 'SupplierView'
            const viewingNonSupplierRoute = !supplierUnguarded && !unguarded && isSupplier

            if (isSupplier && (viewingNonSupplierRoute || viewingSupplierHomeRoute)) {
                next({
                    name: 'SupplierOrderlines',
                    params: {
                        supplierId: this.supplierId,
                        status: 'approved',
                    },
                })
                return
            }

            next()
        })

        this.$options.router.afterEach((to, from) => {
            if (from.name && from.name !== 'LoginView') {
                client.cancelRequests()
            }
        })
    },

    initFilters () {
        const timeUnits = {
            year: 24 * 60 * 60 * 1000 * 365,
            month: 24 * 60 * 60 * 1000 * 365 / 12,
            day: 24 * 60 * 60 * 1000,
            hour: 60 * 60 * 1000,
            minute: 60 * 1000,
            second: 1000,
        }
        const relativeTimeFormat = new Intl.RelativeTimeFormat('en', { numeric: 'auto' })

        this.$vue.filter('asRelativeHumanTime', function (date) {
            const parsedDate = Date.parse(date)
            if (isNaN(date) && !isNaN(parsedDate)) {
                date = new Date(date)
            }
            const now = new Date()
            const elapsed = date - now
            for (let u in timeUnits) {
                if (Math.abs(elapsed) > timeUnits[u] || u === 'second') {
                    return relativeTimeFormat.format(Math.round(elapsed / timeUnits[u]), u)
                }
            }
            return date.toLocaleString()
        })
        this.$vue.filter('asDatetime', function (value) {
            value = moment(value)
            if (!value.isValid()) {
                return '-'
            }

            return value.format('L LT')
        })

        this.$vue.filter('asDate', function (value) {
            value = moment(value)
            if (!value.isValid()) {
                return '-'
            }

            return value.format('L')
        })

        this.$vue.filter('asNumber', function (value) {
            return (new Intl.NumberFormat('en-Gb').format(value))
        })

        this.$vue.filter('beautify', beautify)

        this.$vue.filter('join', function (value, glue) {
            return Object.values(value).join(glue)
        })

        this.$vue.filter('lowerCase', function (value) {
            if (!value) {
                return value
            }

            return value.toString().toLowerCase()
        })

        this.$vue.filter('upperCaseFirst', function (value) {
            if (!value) {
                return value
            }

            return value.toString().charAt(0).toUpperCase() + value.slice(1)
        })

        this.$vue.filter('asMoney', function (value, currency = 'EUR') {
            if (value === null || typeof value === 'undefined') {
                return null
            }
            if (value.hasOwnProperty('value') && value.hasOwnProperty('currency')) {
                value = value.value
                currency = value.currency
            }
            if (isNaN(value)) {
                return value
            }

            let sign = currency
            const currencyObject = CONFIG.CURRENCIES.find(({ value }) => currency.toLowerCase() === value.toLowerCase())
            if (currencyObject) {
                sign = currencyObject.sign
            }

            return sign + parseFloat(value).toFixed(2)
        })

        this.$vue.filter('asCurrencySign', function (currencyCode) {
            if (!currencyCode) {
                return currencyCode
            }

            const currencyObject = CONFIG.CURRENCIES.find(({ value }) => currencyCode.toLowerCase() === value.toLowerCase())
            if (currencyObject) {
                return currencyObject.sign
            }

            return currencyCode
        })

        this.$vue.filter('asAddress', function (location) {
            if (location instanceof Object === false) {
                return location.toString()
            }

            return `${location.address} ${location.houseNumber} ${location.city}, ${location.country}`
        })

        this.$vue.filter('asStatus', function (value) {
            if (!value) {
                return value
            }
            return this.upperCaseFirst(this.beautify(value.toString()).toLowerCase())
        })
    },

    initDirectives () {
        this.$vue.directive('click-outside', {
            bind: function (el, binding, vnode) {
                el.clickOutsideEvent = function (event) {
                    if (!(el === event.target || el.contains(event.target))) {
                        vnode.context[binding.expression](event)
                    }
                }
                document.body.addEventListener('click', el.clickOutsideEvent)
            },
            unbind: function (el) {
                document.body.removeEventListener('click', el.clickOutsideEvent)
            },
        })
    },

    initPlugins () {

    },
    initMixins () {
        this.$vue.mixin({
            data () {
                if (this.$props && this.$props.hasOwnProperty('debug')) {
                    return {}
                }
                return {
                    debug: CONFIG.DEBUG,
                }
            },
            methods: {
                ...mapActions('loading', [ 'addJob', 'finishJob' ]),
                layoutSizes (sm, md, lg, xl) {
                    return Object.entries({ sm, md, lg, xl }).map(([breakpoint, size]) => {
                        return `col-${breakpoint}-${size}`
                    })
                },
                getHomeRoute () {
                    if (!this.isAuthenticated) {
                        return {
                            name: 'LoginView',
                        }
                    }
                    if (this.isSupplier) {
                        return {
                            name: 'SupplierOrderlines',
                            params: {
                                supplierId: this.supplierId,
                                status: 'approved',
                            },
                        }
                    }

                    return {
                        name: 'SuppliersView',
                    }
                },

                isAllowedTo (action) {
                    return true

                    // TODO: Uncomment to enable permission check
                    // const allowedActions = this.$store.state.shop.allowedActions
                    // if (!allowedActions) {
                    //     console.error('YOU DO NOT HAVE ANY ALLOWED ACTIONS!!!')
                    //     return false
                    // }
                    // if (!allowedActions.indexes || !allowedActions.indexes[action]) {
                    //     return false
                    // }
                    // const actionIndex = allowedActions.indexes[action]
                    // if (allowedActions.actions && allowedActions.actions.general && Object.values(allowedActions.actions.general).includes(actionIndex)) {
                    //     return true
                    // }
                    //
                    // return allowedActions.actions.perApplication['suppliercentral.helloprint.com'].includes(actionIndex)
                },
                emitPermissionErrorUserIsNotAllowedTo (action = 'do this action') {
                    this.$root.$emit('notification:global', {
                        message: `You don't have permission to ${action}`,
                    })
                },
            },
            computed: {
                supplierData () {
                    let reference = this
                    while (reference.$parent) {
                        reference = reference.$parent
                        if (reference.supplierDetails) {
                            return reference.supplierDetails
                        }
                    }
                },
                supplierId: {
                    cache: false,
                    get () {
                        if (this.$route.params.supplierId) {
                            return this.$route.params.supplierId
                        }

                        if (this.$store.state.auth && this.$store.state.auth.user && this.$store.state.auth.user.supplierType === 'SUPPLIER' && this.$store.state.auth.user.supplierId) {
                            return this.$store.state.auth.user.supplierId
                        }
                    },
                },
                isSupplier () {
                    return this.$store &&
                        this.$store.state &&
                        this.$store.state.auth &&
                        this.$store.state.auth.user &&
                        this.$store.state.auth.user.supplierType === 'SUPPLIER'
                },
            },
        })
    },
}
