import CONFIG from '@root/config'
import Vue from 'vue'
import App from './App'
import router from './router/router'
import VueCookies from 'vue-cookies'
import store from './store/index'
import VueSetup from './plugins/setup'
import UUID from 'vue-uuid'
import Vuelidate from 'vuelidate'
import PortalVue from 'portal-vue'
import { LicenseManager } from 'ag-grid-enterprise'
import MercurComponents from '@platform-frontend-components/mercur-components'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import CheckboxCell from './components/table/CheckboxCell'
import RouterlinkCell from './components/table/RouterlinkCell'
import ActionsCell from './components/table/ActionsCell'
import AttributeTableActionCell from './components/attributes/AttributeTableActionCell'
LicenseManager.setLicenseKey(CONFIG.AG_GRID_LICENSE_KEY)

Vue.config.productionTip = false

Vue.use(VueCookies)
Vue.use(Vuelidate)
Vue.use(VueRouter)
Vue.use(Vuex)

const vuexStore = new Vuex.Store(store)
Vue.use(VueSetup, {
    store: vuexStore,
    router,
})
Vue.use(PortalVue)

Object.keys(MercurComponents).forEach(name => {
    Vue.component(name, MercurComponents[name])
})
Vue.component('CheckboxCell', CheckboxCell)
Vue.component('RouterlinkCell', RouterlinkCell)
Vue.component('ActionsCell', ActionsCell)
Vue.component('AttributeTableActionCell', AttributeTableActionCell)

Vue.use(UUID)

new Vue({
    router,
    store: vuexStore,
    render: h => h(App),
}).$mount('#app')
