<template>
    <div>
        <mercur-item v-if="isAllowedTo('SupplierCentral/getOrderLinesBySupplierIdsAndStatus')" :expand="true">
            <i class="fas fa-list"></i><span>Orders</span>
            <template slot="content">
                <mercur-item :to="getNestedRoute('OrderListOverview')">
                    <i class="fas fa-list"></i><span>All</span>
                </mercur-item>
                <mercur-item :to="getNestedRoute('OrderListOverview', { orderLineOverviewType: 'delays' })">
                    <i class="fas fa-list"></i><span>Delays</span>
                </mercur-item>
            </template>
        </mercur-item>
        <mercur-item :to="getNestedRoute('OrderSubmissionsLogsView')" v-if="isAllowedTo('SupplierCentral/getAllOrderSubmissions')">
            <i class="fas fa-list-ul"></i><span>Submissions</span>
        </mercur-item>
        <mercur-item :to="getNestedRoute('OrderSubmissionsTransportSettingsView')" v-if="isAllowedTo('SupplierCentral/getAllSupplierTransportSettings')">
            <i class="fas fa-dolly"></i><span>Transport settings</span>
        </mercur-item>
    </div>
</template>

<script>

export default {
    name: 'OrdersSideMenu',
    methods: {
        getNestedRoute (section, params) {
            return this.$router.resolve({
                name: section,
                params: {
                    ...params,
                },
            }).href
        },
    },
}
</script>
