import CONFIG from '@root/config'

class Url {
    constructor () {
        this.base = CONFIG.API.URL
        this.section = null
    }

    /**
     * @param name
     * @returns {Url}
     */
    setSection (name) {
        this.section = name || null

        return this
    }

    /**
     * @param uri
     * @returns {string}
     */
    get (uri) {
        if (uri.match(/[a-zA-Z0-9]*:\/\/[^\s]*/g) !== null) {
            return uri
        }

        let suffix = ''

        if (this.section && this.section in CONFIG.API.PREFIX) {
            suffix = '/' + CONFIG.API.PREFIX[ this.section ]
        }

        return this.base + suffix + uri
    }
}

export default new Url()
