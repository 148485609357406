<template>
    <div>
        <mercur-item :expand="true">
            <i class="fas fa-gifts"></i><span>Products</span>
            <template slot="content">
                <mercur-item :to="getRouteForName('SupplierProducts')">
                    <i class="fas fa-star"></i><span>Product Templates</span>
                </mercur-item>
                <mercur-item :to="getRouteForName('SupplierImportedProducts')">
                    <i class="fas fa-layer-group"></i><span>Product Import</span>
                </mercur-item>
                <mercur-item :to="getRouteForName('SupplierTemplatesForApproval')">
                    <i class="fas fa-clipboard-check"></i><span>Templates For Approval</span>
                </mercur-item>
                <mercur-item :to="getRouteForName('ProductTemplatePropagation')">
                    <i class="fas fa-clipboard-check"></i><span>Template Propagation</span>
                </mercur-item>
                <!--<mercur-item :to="getRouteForName('CommercialProductsView')">
                    <i class="fas fa-layer-group"></i><span>Commercial Products</span>
                </mercur-item>-->
            </template>
        </mercur-item>
        <mercur-item :expand="true">
            <i class="fas fa-cog"></i><span>Settings</span>
            <template slot="content">
                <mercur-item :to="getRouteForName('SupplierPackageConfigurations')">
                    <i class="fas fa-box-open"></i><span>Package configuration</span>
                </mercur-item>
                <mercur-item :to="getRouteForName('SupplierLocations')">
                    <i class="fas fa-city"></i><span>Locations</span>
                </mercur-item>
            </template>
        </mercur-item>
    </div>
</template>

<script>

export default {
    name: 'VirtualSupplierSideMenu',
    methods: {
        getRouteForName (section, params) {
            const routeObject = {
                name: section,
                params: {
                    ...params,
                },
            }

            if (!this.isSupplier) {
                routeObject.params.supplierId = this.$route.params.supplierId
            }

            return routeObject
        },
    },
}
</script>
