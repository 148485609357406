<template>
    <span :class="{'ag-grid__cell--multi-line': value && value.length > 1}">
        <router-link
            v-if="params.routerlink && params.routerlink.to"
            class="router-link-cell"
            v-bind="params.routerlink">
            <span class="d-block" :key="key" v-for="(val, key) in value">
             {{val}}
            </span>
        </router-link>
        <div
            v-else-if="params.routerlink && params.routerlink.onClick"
            class="router-link-cell"
            v-bind="params.routerlink"
            @click="click">
            <span class="d-block" :key="key"  v-for="(val, key) in value">
             {{val}}
            </span>
        </div>
        <div v-else>
            <span class="d-block" :key="key" v-for="(val, key) in value">
             {{val}}
            </span>
        </div>
        <span v-if="params.icon">
            <mercur-tooltip v-if="params.tooltipText">
                <i class="fas fa-question-circle"></i>
                <template slot="label">{{ params.tooltipText }}</template>
            </mercur-tooltip>
        </span>
    </span>
</template>

<script>
export default {
    name: 'RouterlinkCell',
    computed: {
        value () {
            let val = this.params.valueFormatted ? this.params.valueFormatted : this.params.value
            if (Array.isArray(this.params.value)) {
                return this.params.value
            }
            return [val]
        },
    },
    methods: {
        click ($event) {
            this.params.routerlink.onClick(this.value, this.params.data, this.params, $event)
        },
    },
}
</script>

<style lang="scss" scoped>
    .router-link-cell {
        text-decoration: underline;
        cursor: pointer;
    }
</style>
