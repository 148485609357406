<template>
    <div>
        <mercur-item :expand="true" data-test="supplierProductMenuGroup">
            <i class="fas fa-gifts"></i><span>Products</span>
            <template slot="content">
                <mercur-item :to="getRouteForName('SupplierProducts')">
                    <i class="fas fa-star"></i><span>Product Management</span>
                </mercur-item>
                <mercur-item data-test="supplierProductImport" :to="getRouteForName('SupplierImportedProducts')">
                    <i class="fas fa-layer-group"></i><span>Product Import</span>
                </mercur-item>
                <mercur-item :to="getRouteForName('SupplierAttributeMappings')">
                    <i class="fas fa-map"></i><span>Attribute Mappings</span>
                </mercur-item>
            </template>
        </mercur-item>
        <mercur-item :expand="true">
            <i class="fas fa-cog"></i><span>Settings</span>
            <template slot="content">
                <mercur-item :to="getRouteForName('SupplierEdit')">
                    <i class="fas fa-cog"></i><span>Supplier settings</span>
                </mercur-item>
                <mercur-item :to="getRouteForName('SupplierUsers')">
                    <i class="fas fa-user-cog"></i><span>Users</span>
                </mercur-item>
                <mercur-item :to="getRouteForName('SupplierPackageConfigurations')">
                    <i class="fas fa-box-open"></i><span>Package configuration</span>
                </mercur-item>
                <mercur-item :to="getRouteForName('SupplierLocations')">
                    <i class="fas fa-city"></i><span>Locations</span>
                </mercur-item>
                <!-- <mercur-item :to="getRouteForName('CutOffTimesOverview')">
                    <i class="fas fa-stopwatch"></i><span>Cutoff Times</span>
                </mercur-item> -->
                <mercur-item :to="getRouteForName('SupplierHolidays')">
                    <i class="fas fa-umbrella-beach"></i><span>Holidays</span>
                </mercur-item>
            </template>
        </mercur-item>
        <mercur-item :to="getRouteForName('SupplierTemplates', {templateSection: 'supplier'})">
            <i class="fas fa-file-pdf"></i><span>Templates</span>
        </mercur-item>
    </div>
</template>

<script>
export default {
    name: 'SupplierSideMenu',
    data () {
        return {
        }
    },
    methods: {
        getRouteForName (section, params) {
            const routeObject = {
                name: section,
                params: {
                    ...params,
                },
            }

            if (!this.isSupplier) {
                routeObject.params.supplierId = this.$route.params.supplierId
            }

            return routeObject
        },
    },
}
</script>

<style>
.badge {
    position: absolute;
    right: 10px;
}
</style>
