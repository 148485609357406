<template>
    <div>
        <component
            :is="sideMenuComponent"
        ></component>
    </div>
</template>

<script>

import SupplierSideMenu from './SupplierSideMenu'
import VirtualSupplierSideMenu from './VirtualSupplierSideMenu'
import SuppliersSideMenu from './SuppliersSideMenu'
import OrdersSideMenu from './OrdersSideMenu'
import LogisticsSideMenu from './LogisticsSideMenu'
import PricingSideMenu from './PricingSideMenu'
import QuotesSideMenu from './QuotesSideMenu'
import AccessControlSideMenu from './AccessControlSideMenu'
import { mapGetters } from 'vuex'
import ProductContentManagementSideMenu from './ProductContentManagementSideMenu'
import GlobalComplaintsSideMenu from './GlobalComplaintsSideMenu'
import ArtworkManagementSideMenu from './ArtworkManagementSideMenu'

export default {
    name: 'SideMenu',
    components: { GlobalComplaintsSideMenu, ProductContentManagementSideMenu, OrdersSideMenu, SupplierSideMenu, SuppliersSideMenu, LogisticsSideMenu, QuotesSideMenu, AccessControlSideMenu, VirtualSupplierSideMenu, PricingSideMenu, ArtworkManagementSideMenu },
    computed: {
        ...mapGetters('auth', ['user']),
        sideMenuComponent () {
            if (!this.isSupplier && this.supplierId === this.user.supplierId) {
                return 'virtual-supplier-side-menu'
            }

            if (this.isSupplier || this.supplierId) {
                return 'supplier-side-menu'
            }

            if (this.isSectionActive('suppliers')) {
                return 'suppliers-side-menu'
            }

            if (this.isSectionActive('orders')) {
                return 'orders-side-menu'
            }

            if (this.isSectionActive('logistics')) {
                return 'logistics-side-menu'
            }

            if (this.isSectionActive('quotes')) {
                return 'quotes-side-menu'
            }

            if (this.isSectionActive('access-control')) {
                return 'access-control-side-menu'
            }

            if (this.isSectionActive('product-content-management')) {
                return 'product-content-management-side-menu'
            }

            if (this.isSectionActive('pricing')) {
                return 'pricing-side-menu'
            }

            if (this.isSectionActive('complaints_global')) {
                return 'global-complaints-side-menu'
            }

            if (this.isSectionActive('artwork_management')) {
                return 'artwork-management-side-menu'
            }

            return null
        },
    },
    methods: {
        isSectionActive (sectionName) {
            return this.$route.matched.some(route => {
                return route.meta.section === sectionName
            })
        },

        checkIfSectionHasElements (value) {
            if (value === null) {
                this.$emit('checkIfSectionHasElements', false)
                return
            }
            this.$emit('checkIfSectionHasElements', true)
        },
    },

    watch: {
        sideMenuComponent (value) {
            this.checkIfSectionHasElements(value)
        },
    },

    mounted () {
        this.checkIfSectionHasElements(this.sideMenuComponent)
    },
}
</script>
