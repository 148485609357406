import jwt from 'jwt-simple'

import CONFIG from '@root/config'
import {
    SET_USER,
    SET_LAST_VISITED_PATH,
    SET_ALLOWED_ACTIONS,
} from '@/store/mutation-types'

export default {
    namespaced: true,
    state: {
        user: null,
        path: null,
        allowedActions: null,
    },

    mutations: {
        [SET_USER] (state, user) {
            state.user = user
        },

        [SET_LAST_VISITED_PATH] (state, path) {
            state.path = path
        },

        [ SET_ALLOWED_ACTIONS ] (state, actions) {
            this._vm.$set(state, 'allowedActions', actions)
        },
    },

    actions: {
        loginGuest (context, userData) {
            context.commit(SET_USER, {
                ...userData,
                guest: true,
            })
        },

        updateAuth (context, { token, explicit }) {
            if (context.state.user === null && !explicit) {
                return
            }
            this._vm.$api.setToken(token)
            const decoded = jwt.decode(token, '', true)
            context.commit(SET_USER, {
                guest: false,
                ...decoded,
                token,
            })
            this._vm.$cookies.set(CONFIG.COOKIES.TOKEN.KEY, token, new Date(decoded.exp * 1000), '/')
        },

        login (context, token) {
            context.dispatch('updateAuth', { token, explicit: true })
            return context.state.path
        },

        logout (context) {
            context.commit(SET_USER, null)
            context.dispatch('notifications/clearNotifications', null, {
                root: true,
            })
            this._vm.$api.setToken('')
            this._vm.$cookies.remove(CONFIG.COOKIES.TOKEN.KEY, '/')
            this._vm.$cookies.remove(CONFIG.COOKIES.SESSION.KEY, '/')
            this._vm.$cookies.remove(CONFIG.COOKIES.LOCALE.KEY, '/')
        },

        check (context) {
            const token = this._vm.$cookies.get(CONFIG.COOKIES.TOKEN.KEY)

            if (token) {
                context.dispatch('login', token)
            }
        },

        patchUser ({ commit, state }, data) {
            commit(SET_USER, Object.assign(state.user, data))
        },

        guard (context, { to, from, next }) {
            context.commit(SET_LAST_VISITED_PATH, to.path)
            if (!context.getters.isGuest) {
                return next()
            }
            context.dispatch('logout')
            return next(CONFIG.NAVIGATION.LOGIN_PATH)
        },

        async recoverPassword ({ dispatch }, params) {
            const url = CONFIG.API.ROUTES.ACCOUNT.REQUEST_PASSWORD_RECOVERY

            dispatch('loading/addJob', url, {
                root: true,
            })

            return new Promise((resolve, reject) => {
                this._vm.$api.post(url, params).then(({ data }) => {
                    resolve(data)
                }).catch(err => {
                    this.$emit('error', err.response)
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', url, {
                        root: true,
                    })
                })
            })
        },

        setAllowedActions ({ commit }, data) {
            commit(SET_ALLOWED_ACTIONS, data)
        },
    },

    getters: {
        user (state) {
            return state.user || {}
        },

        isGuest (state) {
            return state.user === null || state.user.guest === true
        },

        isAuthenticated (state) {
            return state.user !== null && state.user.guest !== true
        },
    },
}
