<template>
    <div class="d-flex justify-content-end">
        <template v-if="iconActions">
            <div v-for="(actionButton, key) in iconActions" :key="key">
                <mercur-tooltip v-if="actionButton.text">
                    <template slot="label">{{actionButton.text}}</template>
                    <mercur-button :disabled="isDisabled(actionButton)" v-bind="actionButton.attr" :to="to(actionButton)" @click="onClick(actionButton)" class="btn btn-icon text-grey">
                        <i :class="actionButton.icon" />
                    </mercur-button>
                </mercur-tooltip>
                <mercur-button v-else :disabled="isDisabled(actionButton)" v-bind="actionButton.attr" :to="to(actionButton)" @click="onClick(actionButton)" class="btn btn-icon text-grey">
                    <i :class="actionButton.icon" />
                </mercur-button>
            </div>
        </template>
        <component :is="!isSingleAction ? 'mercur-menu' : 'div'" appendToBody>
            <template v-if="!isSingleAction && actions.length > 0">
                <mercur-button class="btn btn-icon"><i class="fas fa-ellipsis-v text-grey"></i></mercur-button>
                <div slot="dropdown">
                    <template v-for="(actionButton, key) in actions">
                        <mercur-item v-if="isDisabled(actionButton)" :disabled="isDisabled(actionButton)" :key="`action-${key}`">
                            <i v-if="actionButton.icon" :class="actionButton.icon"></i>
                            <span v-if="actionButton.text">{{actionButton.text}}</span>
                        </mercur-item>
                        <mercur-item v-else-if="to(actionButton)" v-bind="actionButton.attr" :key="`linki-${key}`" :to="to(actionButton)">
                            <i v-if="actionButton.icon" :class="actionButton.icon"></i>
                            <span v-if="actionButton.text">{{actionButton.text}}</span>
                        </mercur-item>
                        <mercur-item v-else-if="!to(actionButton)" v-bind="actionButton.attr" @click.native="onClick(actionButton)" :key="`btn-${key}`">
                            <i v-if="actionButton.icon" :class="actionButton.icon"></i>
                            <span v-if="actionButton.text">{{actionButton.text}}</span>
                        </mercur-item>
                    </template>
                </div>
            </template>
            <template v-else>
                <div v-for="(actionButton, key) in actions" :key="key">
                    <mercur-tooltip v-if="actionButton.text">
                        <template slot="label">{{actionButton.text}}</template>
                        <mercur-button :disabled="isDisabled(actionButton)" v-bind="actionButton.attr" :to="to(actionButton)" @click="onClick(actionButton)" class="btn btn-icon text-grey">
                            <i :class="actionButton.icon" />
                        </mercur-button>
                    </mercur-tooltip>
                    <mercur-button v-else :disabled="isDisabled(actionButton)" v-bind="actionButton.attr" :to="to(actionButton)" @click="onClick(actionButton)" class="btn btn-icon text-grey">
                        <i :class="actionButton.icon" />
                    </mercur-button>
                </div>
            </template>
        </component>
    </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
    name: 'ActionsCell',
    computed: {
        actions () {
            return this.params.actions.filter(actionButton => {
                if (actionButton.isIcon) {
                    return false
                }
                if (actionButton.hide && {}.toString.call(actionButton.hide) === '[object Function]') {
                    return !actionButton.hide(this.params)
                }
                return !actionButton.hide
            })
        },
        iconActions () {
            return this.params.actions.filter(actionButton => {
                if (!actionButton.isIcon) {
                    return false
                }
                if (actionButton.hide && {}.toString.call(actionButton.hide) === '[object Function]') {
                    return !actionButton.hide(this.params)
                }
                return !actionButton.hide
            })
        },
        isSingleAction () {
            return this.actions.length === 1
        },
    },
    methods: {
        to (actionButton) {
            if (actionButton.to && {}.toString.call(actionButton.to) === '[object Function]') {
                return actionButton.to(this.params)
            }
            return actionButton.to
        },
        onClick (actionButton) {
            if (actionButton.onClick) {
                actionButton.onClick(this.params)
                return
            }
            return () => {}
        },
        isDisabled (actionButton) {
            if (actionButton.disabled && {}.toString.call(actionButton.disabled) === '[object Function]') {
                return actionButton.disabled(this.params)
            }
            return actionButton.disabled
        },
    },
})
</script>
