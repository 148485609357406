import { SET_CARRIERS, SET_SERVICELEVELS } from '@/store/mutation-types'
import CONFIG from '@root/config'

export default {
    namespaced: true,

    state: {
        carriers: null,
        serviceLevels: null,
    },

    mutations: {
        [ SET_CARRIERS ] (state, carriers) {
            this._vm.$set(state, 'carriers', carriers)
        },
        [ SET_SERVICELEVELS ] (state, serviceLevels) {
            this._vm.$set(state, 'serviceLevels', serviceLevels)
        },
    },

    actions: {
        fetchCarriers ({ dispatch, commit, state }) {
            dispatch('loading/addJob', SET_CARRIERS, {
                root: true,
            })
            return new Promise((resolve, reject) => {
                const url = CONFIG.API.ROUTES.CARRIERS.OVERVIEW
                const data = {
                    'request': {
                        'startRow': 0,
                        'endRow': 9999,
                        'rowGroupCols': [],
                        'valueCols': [],
                        'pivotCols': [],
                        'pivotMode': false,
                        'groupKeys': [],
                        'filterModel': {},
                        'sortModel': [{
                            'colId': 'carrierName',
                            'sort': 'asc',
                        }],
                    },
                }
                this._vm.$api.post(url, data).then(({ data }) => {
                    commit(SET_CARRIERS, data.data.items)
                    resolve(data)
                }).catch((err) => {
                    if (CONFIG.DEBUG) {
                        console.error(err)
                    }
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', SET_CARRIERS, {
                        root: true,
                    })
                })
            })
        },

        fetchAllServiceLevels ({ dispatch, commit, state }) {
            dispatch('loading/addJob', SET_SERVICELEVELS, {
                root: true,
            })
            return new Promise((resolve, reject) => {
                const url = CONFIG.API.ROUTES.CARRIERS.SERVICELEVELS.OVERVIEW_ALL
                const data = {
                    'request': {
                        'startRow': 0,
                        'endRow': 9999,
                        'rowGroupCols': [],
                        'valueCols': [],
                        'pivotCols': [],
                        'pivotMode': false,
                        'groupKeys': [],
                        'filterModel': {},
                        'sortModel': [{
                            'colId': 'serviceLevelName',
                            'sort': 'asc',
                        }],
                    },
                }
                this._vm.$api.post(url, data).then(({ data }) => {
                    commit(SET_SERVICELEVELS, data.data.items)
                    resolve(data)
                }).catch((err) => {
                    if (CONFIG.DEBUG) {
                        console.error(err)
                    }
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', SET_SERVICELEVELS, {
                        root: true,
                    })
                })
            })
        },
    },
    getters: {
        getCarriers (state) {
            return state.carriers
        },
    },
}
