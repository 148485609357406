<template>
    <div>
        <mercur-checkbox :disabled="disabled" v-model="value"></mercur-checkbox>
    </div>
</template>

<script>
export default {
    name: 'CheckboxCell',
    computed: {
        value: {
            get () {
                return this.params.getValue()
            },
            set (value) {
                this.params.setValue(value, this.params.data)
            },
        },
        disabled () {
            if (this.params.hasOwnProperty('disabled') && {}.toString.call(this.params.disabled) === '[object Function]') {
                return this.params.disabled(this.params)
            }
            return this.params.disabled
        },
    },
}
</script>
