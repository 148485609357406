import axios from 'axios'

import CONFIG from '@root/config'
import url from './Url'

class Client {
    constructor () {
        this.url = url
        this.sources = []
        this.configure()
    }

    withAccessTokenReceiver (accessTokenReceiver) {
        this.accessTokenReceiver = accessTokenReceiver
        return this
    }

    withErrorInterceptor (errorInterceptor) {
        axios.interceptors.response.use((response) => {
            return response
        }, errorInterceptor)
        return this
    }

    configure () {
        axios.defaults.baseURL = CONFIG.API.URL
        axios.defaults.headers.common[ 'X-Requested-With' ] = 'XMLHttpRequest'
        axios.defaults.transformResponse = (axios.defaults.transformResponse || []).concat((data, headers) => {
            if (headers[ 'x-access-token' ]) {
                this.accessTokenReceiver(headers[ 'x-access-token' ])
            }

            return data
        })
    }

    setToken (token = null) {
        axios.defaults.headers.common[ 'Authorization' ] = `Bearer ${token}`
    }

    setSession (session) {
        axios.defaults.headers.common[ 'X-Session' ] = session
    }

    setLocale (locale) {
        axios.defaults.headers.common[ 'X-Locale' ] = locale
    }

    cancelRequests () {
        if (this.sources.length) {
            this.sources.forEach((item) => {
                item.source.cancel()
            })
            this.sources = []
        }
    }

    /**
     * @param uri
     * @param section
     * @returns {AxiosPromise<any>}
     */
    get (uri, section = null) {
        const url = this.url.setSection(section).get(uri)
        const source = axios.CancelToken.source()
        this.sources.push({
            url: url,
            source: source,
        })

        return axios.get(url, {
            cancelToken: source.token,
        })
    }

    /**
     *
     * @param uri
     * @param data
     * @param section
     * @returns {AxiosPromise<any>}
     */
    post (uri, data = {}) {
        const url = this.url.get(uri)
        const source = axios.CancelToken.source()
        this.sources.push({
            url: url,
            source: source,
        })

        return axios.post(url, data, {
            cancelToken: source.token,
        })
    }

    /**
     * @param uri
     * @param data
     * @param section
     * @returns {AxiosPromise<any>}
     */
    put (uri, data = {}, section = null) {
        const url = this.url.setSection(section).get(uri)

        return axios.put(url, data)
    }

    /**
     * @param uri
     * @param section
     * @returns {AxiosPromise}
     */
    delete (uri) {
        const url = this.url.get(uri)
        const source = axios.CancelToken.source()

        this.sources.push({
            url: url,
            source: source,
        })

        return axios.delete(url, {
            cancelToken: source.token,
        })
    }
}

export default new Client()
