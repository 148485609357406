<template>
    <div data-test="notificationGroupWrapper" class="notification">
        <div data-test="notificationGroupContent" @click="expandItem(type)" class="d-flex flex-wrap align-items-center justify-content-between">
            <mercur-button class="btn btn-icon btn-invert">
                <i :class="expandedItem === type ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
            </mercur-button>
            <i class="mr-2" :class="'fas fa-'+icon"></i>
            <div data-test="notificationGroupOrder" class="flex-grow-1" v-if="type === 'orders'">
                <span>There are {{notificationsType.length}} orders ready for production</span>
            </div>
            <div data-test="notificationGroupComplaint" class="flex-grow-1" v-else-if="type === 'complaints'">
                <span>There are {{notificationsType.length}} complaints to check</span>
            </div>
            <div data-test="notificationGroupTasksAlerts" class="flex-grow-1" v-else>
                <span>There are {{notificationsType.length}} {{type}}</span>
            </div>
            <div>
                <mercur-button data-test="notificationGroupDelete" @click="deleteAllNotifications(type)" class="btn btn-icon btn-invert">
                    <i class="fas fa-trash"></i>
                </mercur-button>
            </div>
        </div>
        <transition name="accordion" @enter="startExpanding" @after-enter="endExpanding" @before-leave="startExpanding" @after-leave="endExpanding">
            <div data-test="notificationGroupItem" v-show="expandedItem === type">
                <notification-item
                    v-for="(notification, i) in notifications[type]"
                    :key="notification+i"
                    :type="type"
                    :showIcon="false"
                    :notification="notification">
                </notification-item>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import NotificationItem from './NotificationItem'
export default {
    components: {
        NotificationItem,
    },
    props: {
        notifications: Object,
        notificationsType: Array,
        expandedItem: [Boolean, String],
        type: String,
        icon: String,
    },
    data: () => {
        return {
            isNotificationsOpen: false,
        }
    },
    methods: {
        ...mapActions({ dismissNotification: 'notifications/dismissNotification' }),
        expandItem (type) {
            this.$emit('update:expandedItem', this.expandedItem === type ? !type : type)
        },
        deleteAllNotifications (type) {
            this.dismissNotification({ type })
        },
        startExpanding (el) {
            el.style.height = el.scrollHeight + 'px'
        },
        endExpanding (el) {
            el.style.height = ''
        },
    },
}
</script>
