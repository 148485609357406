<template>
    <div>
        <mercur-item :to="getRouteForName('ProductContentCategoriesView')">
            <i class="fas fa-tags"></i><span>Categories</span>
        </mercur-item>
    </div>
</template>

<script>

export default {
    name: 'ProductContentManagementSideMenu',
    methods: {
        getRouteForName (section, params) {
            return this.$router.resolve({
                name: section,
                params: {
                    ...params,
                },
            }).href
        },
    },
}
</script>
