// general

// suppliers
export const SET_SUPPLIERS = 'SET_SUPPLIERS'
export const SET_SUPPLIER_LOCATIONS = 'SET_SUPPLIER_LOCATIONS'
export const SET_LOCATION_SERVICELEVELS = 'SET_LOCATION_SERVICELEVELS'

// holidays
export const SET_SUPPLIER_HOLIDAYS = 'SET_SUPPLIER_HOLIDAYS'
export const SET_LOCATION_HOLIDAYS = 'SET_LOCATION_HOLIDAYS'

// contact persons
export const SET_SUPPLIER_CONTACT_PERSONS = 'SET_SUPPLIER_CONTACT_PERSONS'
export const SET_LOCATION_CONTACT_PERSONS = 'SET_LOCATION_CONTACT_PERSONS'

// product attributes
export const SET_PRODUCT_ATTRIBUTES = 'SET_PRODUCT_ATTRIBUTES'
export const SET_PRODUCT_ATTRIBUTE_POSTFIXES = 'SET_PRODUCT_ATTRIBUTE_POSTFIXES'

// product details
export const SET_PRODUCT_DETAILS = 'SET_PRODUCT_DETAILS'
export const SET_ARTWORK_FILES = 'SET_ARTWORK_FILES'

// carriers
export const SET_CARRIERS = 'SET_CARRIERS'
export const SET_SERVICELEVELS = 'SET_SERVICELEVELS'

// user
export const SET_USER = 'SET_USER'
export const SET_LAST_VISITED_PATH = 'SET_PATH'
export const SET_ALLOWED_ACTIONS = 'SET_ALLOWED_ACTIONS'
export const SET_NOTIFICATIONS_COMPLAINTS_OUTSTANDING = 'SET_NOTIFICATIONS_COMPLAINTS_OUTSTANDING'

// loading
export const ADD_JOB = 'ADD_JOB'
export const FINISH_JOB = 'FINISH_JOB'

// finance
export const SET_CURRENCY_CONVERSION_TABLE = 'SET_CURRENCY_CONVERSION_TABLE'
export const SET_AVAILABLE_CURRENCIES = 'SET_AVAILABLE_CURRENCIES'

// notifications
export const SET_SERVER_NOTIFICATIONS = 'SET_SERVER_NOTIFICATIONS'
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION'
export const ADD_NOTIFICATION_NONPERSISTENT = 'ADD_NOTIFICATION_NONPERSISTENT'
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS'
