import { SET_SUPPLIERS, SET_SUPPLIER_LOCATIONS, SET_LOCATION_SERVICELEVELS } from '@/store/mutation-types'
import CONFIG from '@root/config'

export default {
    namespaced: true,

    state: {
        suppliers: [],
        supplierLocations: {},
        locationServiceLevels: {},
    },

    mutations: {
        [ SET_SUPPLIERS ] (state, suppliers) {
            this._vm.$set(state, 'suppliers', suppliers)
        },

        [ SET_SUPPLIER_LOCATIONS ] (state, { supplierId, locations }) {
            this._vm.$set(state.supplierLocations, supplierId, locations)
        },

        [ SET_LOCATION_SERVICELEVELS ] (state, { locationId, serviceLevels }) {
            this._vm.$set(state.locationServiceLevels, locationId, serviceLevels)
        },
    },

    actions: {
        fetch ({ commit }, { url, mutation }) {
        },

        fetchSuppliers ({ dispatch, commit }) {
            dispatch('loading/addJob', SET_SUPPLIERS, {
                root: true,
            })
            return new Promise((resolve, reject) => {
                const url = CONFIG.API.ROUTES.SUPPLIERS.OVERVIEW
                this._vm.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                    commit(SET_SUPPLIERS, data.data)
                    resolve(data)
                }).catch((err) => {
                    if (CONFIG.DEBUG) {
                        console.log(err)
                    }
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', SET_SUPPLIERS, {
                        root: true,
                    })
                })
            })
        },

        fetchSupplierById ({ dispatch }, supplierId) {
            dispatch('loading/addJob', 'GET_SUPPLIER_BY_ID', {
                root: true,
            })
            return new Promise((resolve, reject) => {
                const url = CONFIG.API.ROUTES.SUPPLIERS.GET.replace('{supplierId}', supplierId)
                this._vm.$api.get(url).then(({ data }) => {
                    resolve(data.data)
                }).catch((err) => {
                    if (CONFIG.DEBUG) {
                        console.log(err)
                    }
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', 'GET_SUPPLIER_BY_ID', {
                        root: true,
                    })
                })
            })
        },

        setSuppliers ({ commit }, data) {
            commit(SET_SUPPLIERS, data)
        },

        fetchSupplierLocations ({ dispatch, commit }, supplierId) {
            const url = CONFIG.API.ROUTES.SUPPLIERS.LOCATIONS.replace('{supplierId}', supplierId)
            dispatch('loading/addJob', url, {
                root: true,
            })

            return new Promise((resolve, reject) => {
                this._vm.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                    const locations = data.data.items.map((location) => {
                        if (location.locationType === 'FACILITY') {
                            if (!location.hasOwnProperty('carriers')) {
                                location.serviceLevels = []
                            }

                            if (location.hasOwnProperty('workingDays')) {
                                location.workingDays.map(workingDay => {
                                    workingDay.active = true
                                })
                            }
                        }

                        location.facilitySettings = Object.fromEntries(Object.entries(location.facilitySettings || {}))

                        return location
                    })
                    commit(SET_SUPPLIER_LOCATIONS, { supplierId, locations })
                    resolve(locations)
                }).catch(data => {
                    this._vm.$root.$emit('notification:global', {
                        message: `Getting location details failed. Please try again.`,
                        type: 'error',
                        errors: data,
                    })
                    if (CONFIG.DEBUG) {
                        console.log(data)
                    }
                    reject(data)
                }).finally(() => {
                    dispatch('loading/finishJob', url, {
                        root: true,
                    })
                })
            })
        },

        fetchLocations ({ dispatch, commit, getters }) {
            return dispatch('fetchSuppliers').then(async ({ data }) => {
                for (let supplier of data) {
                    const supplierId = supplier.supplierId
                    await dispatch('fetchSupplierLocations', supplierId).then(({ data }) => {
                        const locations = data.map(location => {
                            location.supplier = supplier

                            return location
                        })
                        commit(SET_SUPPLIER_LOCATIONS, { supplierId, locations })
                    })
                }
            })
        },

        fetchSupplierLocationsWithServiceLevelsWithCountries ({ dispatch, commit, getters }, supplierId) {
            const url = CONFIG.API.ROUTES.SUPPLIERS.LOCATIONS_WITH_SERVICELEVELS_WITH_COUNTRIES.replace('{supplierId}', supplierId)
            dispatch('loading/addJob', url, {
                root: true,
            })
            return new Promise((resolve, reject) => {
                this._vm.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                    resolve(data.data.items)
                }).catch((err) => {
                    if (CONFIG.DEBUG) {
                        console.log(err)
                    }
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', url, {
                        root: true,
                    })
                })
            })
        },

        fetchLocationServiceLevels ({ dispatch, commit, getters }, { supplierId, locationId, countryCode = null }) {
            const url = CONFIG.API.ROUTES.SUPPLIERS.LOCATION_SERVICELEVELS.replace('{supplierId}', supplierId).replace('{locationId}', locationId)
            dispatch('loading/addJob', url, {
                root: true,
            })
            return new Promise((resolve, reject) => {
                this._vm.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                    if (!countryCode) {
                        commit(SET_LOCATION_SERVICELEVELS, { locationId, serviceLevels: data.data.items })
                        resolve(data.data.items)
                        return
                    }
                    const countriesUrl = CONFIG.API.ROUTES.SUPPLIERS.LOCATION_SERVICELEVELS_COUNTRIES.replace('{supplierId}', supplierId)
                    this._vm.$api.post(countriesUrl, {
                        serviceLevelIds: data.data.items.map(serviceLevel => serviceLevel.serviceLevelId),
                        countryFrom: countryCode,
                    }).then(countriesData => {
                        const serviceLevels = data.data.items.map(serviceLevel => {
                            if (countriesData.data.data[serviceLevel.serviceLevelId]) {
                                this._vm.$set(serviceLevel, 'countriesTo', countriesData.data.data[serviceLevel.serviceLevelId])
                                const shipTo = Object.fromEntries(serviceLevel.countriesTo.map(shipToCountryCode => {
                                    return [shipToCountryCode, serviceLevel.pickupTime || '18:00']
                                }))
                                this._vm.$set(serviceLevel, 'shipTo', shipTo)
                            }
                            return serviceLevel
                        })

                        commit(SET_LOCATION_SERVICELEVELS, { locationId, serviceLevels })
                        resolve(serviceLevels)
                    })
                }).catch((err) => {
                    if (CONFIG.DEBUG) {
                        console.log(err)
                    }
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', url, {
                        root: true,
                    })
                })
            })
        },
    },
    getters: {
        getSupplierById (state) {
            return id => state.suppliers.find(item => {
                return item.supplierId === id
            })
        },

        getLocationsBySupplierId (state) {
            return supplierId => state.supplierLocations[supplierId]
        },

        getLocationBySupplierIdAndLocationId (state) {
            return (supplierId, locationId) => {
                if (!state.supplierLocations[supplierId]) {
                    return null
                }

                return state.supplierLocations[supplierId].find(location => location.locationId === locationId)
            }
        },

        getOfficeBySupplierIdAndFacilityId (state, getters) {
            return (supplierId, facilityId) => {
                if (!state.supplierLocations[supplierId]) {
                    return null
                }

                const facility = getters.getLocationBySupplierIdAndLocationId(supplierId, facilityId)
                if (!facility || !facility.parentId) {
                    return facility
                }

                return getters.getLocationBySupplierIdAndLocationId(supplierId, facility.parentId)
            }
        },

        getLocations (state, getters) {
            return Object.values(state.supplierLocations).flat()
        },

        getCurrencyCodeForOrderLine (state, getters) {
            return (orderLine) => {
                const fallback = ''

                if (!orderLine || !orderLine.supplierProducer || !orderLine.supplierProducer.facilityId) {
                    return fallback
                }

                const office = getters.getOfficeBySupplierIdAndFacilityId(orderLine.supplierProducer.supplierId, orderLine.supplierProducer.facilityId)

                if (!office || !office.financeSettings || !office.financeSettings.currency) {
                    return fallback
                }

                return office.financeSettings.currency
            }
        },
    },
}
