<template>
    <div class="page-container">
        <div class="c-app">
            <div class="header" data-test="mainToolbar">
                <div class="d-flex header__content position-relative">
                    <top-menu class="flex-grow-1 d-flex align-items-center"></top-menu>
                    <account-menu class="flex-grow-1 d-flex justify-content-end align-items-center"></account-menu>
                    <mercur-progress-bar data-test="loading" indeterminate v-if="getPendingJobs.length" />
                </div>
            </div>
            <div class="app d-flex flex-grow-1 w-100">

                <div class="app__sidebar bg-white shadow" :class="{'app__sidebar-closed': !isSideBarVisible || !isAuthenticated}" >
                    <side-menu @checkIfSectionHasElements="setSideBarStatus" class="fill"></side-menu>
                </div>
                <div class="app__content flex-grow-1" :class="{'app__content-margin': isSideBarVisible && isAuthenticated}">
                    <breadcrumbs class="my-2" :routeview="$refs.routeview"  v-if="isAuthenticated"></breadcrumbs>
                    <div class="d-flex flex-grow-1 main-content__inner">
                        <router-view ref="routeview"></router-view>
                        <portal-target class="fixed-bottom" name="actionbar"></portal-target>
                    </div>
                    <portal-target class="fixed-right" name="sidebarRight"></portal-target>
                </div>

            </div>
        </div>
        <mercur-alert data-test="notification" :timeout.sync="timeout" :notification.sync="notification" />
        <iframe class="downloadiframe" ref="downloadiframe"></iframe>

    </div>
</template>

<style lang="scss">

    @import 'assets/scss/main';

</style>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
import TopMenu from '@/components/TopMenu'
import AccountMenu from '@/components/AccountMenu'
import { mapGetters } from 'vuex'
import CONFIG from '@root/config'
import SideMenu from './views/sidemenus/SideMenu'
import collect from 'collect.js'
export default {
    name: 'App',
    components: { SideMenu, Breadcrumbs, TopMenu, AccountMenu },
    computed: {
        ...mapGetters('loading', [ 'getPendingJobs' ]),
        ...mapGetters('auth', [ 'isAuthenticated' ]),
        sideDrawerPersistence () {
            return null
        },
        isRightSideBarVisible () {
            return !!this.$route.meta.rightSideBar
        },
    },
    data () {
        return {
            notificationShow: false,
            notification: {},
            sideDrawerActive: false,
            sideDrawerMouseEventTimeout: null,
            isSideBarVisible: true,
            timeout: 3000,
        }
    },

    methods: {
        setLocale () {
            if (!this.$cookies.get(CONFIG.COOKIES.LOCALE.KEY)) {
                this.$cookies.set(CONFIG.COOKIES.LOCALE.KEY, 'en-GB')
            }

            this.$api.setLocale(this.$cookies.get(CONFIG.COOKIES.LOCALE.KEY))
        },
        setSession () {
            if (!this.$cookies.get(CONFIG.COOKIES.SESSION.KEY)) {
                this.$cookies.set(CONFIG.COOKIES.SESSION.KEY, this.$uuid.v4())
            }

            this.$api.setSession(this.$cookies.get(CONFIG.COOKIES.SESSION.KEY))
        },
        handleNotificationEvent (event) {
            const notification = event

            let errors = (event.errors || [])
            let errorMessages = []

            if (errors instanceof Error) {
                errorMessages = [errors.message]
            } else if ('data' in errors) {
                let defaultMessage = errors.data.defaultMessage || ''
                if (defaultMessage) {
                    errorMessages.push(defaultMessage)
                }
                const subErrors = (errors.data.errorDetails || [])
                if (subErrors.length > 0) {
                    errorMessages[errorMessages.length - 1] += ':'
                }
                let subErrorsMessages = []
                subErrors.forEach(error => {
                    if (error.message) {
                        subErrorsMessages.push(error.message)
                    }
                })
                if (subErrorsMessages.length > 0) {
                    errorMessages.push(subErrorsMessages.join('; '))
                }
            }

            if (errorMessages.length === 0 && notification.message) {
                errorMessages.push(notification.message)
            }

            notification.message = errorMessages.join('\n\r')

            if (notification.clear) {
                this.notificationShow = false
                this.notification = notification
                return
            }
            this.timeout = notification.type === 'error' ? 10000 : 3000
            if (notification.errors) {
                try {
                    const errors = collect(notification.errors)

                    notification.errors = errors.flatten().filter(value => typeof value === 'string').map(message => {
                        return {
                            message,
                        }
                    }).all()

                    if (notification.message) {
                        this.$store.dispatch('notifications/addNotificationNonPersistent', { type: 'alerts', notification })
                    }
                } catch (e) {

                }
            }

            this.notification = notification
            this.notificationShow = true
        },

        setSideBarStatus (status) {
            this.isSideBarVisible = status
        },

    },

    mounted () {
        this.$bus.$on('userUnauthorized', () => {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push({ name: 'LoginView' })
                this.$root.$emit('notification:global', {
                    message: 'Your session has expired.',
                    type: 'error',
                })
            })
        })
    },

    created () {
        this.setSession()
        this.setLocale()
        this.$root.$on('notification:global', this.handleNotificationEvent)
        this.$bus.$on('notification:global', this.handleNotificationEvent)
        this.$bus.$on('DOWNLOAD_URL_RESOLVED', url => {
            this.$refs.downloadiframe.src = url
        })
        this.$watch('$store.state.auth.user.accountId', accountId => {
            if (!accountId) {
                return
            }
            this.$store.dispatch('finance/getCurrencyConversionTable')
        }, {
            immediate: true,
        })
    },
    beforeCreate () {
        window.sc = this
    },
}
</script>

<style lang="scss" scoped>
    .fixed-bottom {
        position: fixed;
        bottom: 18px;
        padding-left: 18px;
        z-index: 20;
        overflow: hidden;
        padding-bottom: 4px;

        &--right {
            right: 48px;
        }

        &--boxed {
            background-color: white;
            border: 1px solid #dedede;
        }

        /deep/ .card-padding {
            padding-left: 18px;
            padding-bottom: 10px;
        }
    }

    .fixed-right {
        position: fixed;
        bottom: 0;
        right: 0;
        height: 100%;
        z-index: 10;
    }
</style>
