<template>
    <div class="actions">
        <mercur-tooltip>
            <mercur-button v-if="params.openEditAttributeOption" :disabled="isDisabled(params)" class="btn btn-icon btn-icon-sm" @click="openEditAttributeOption" data-test="attributeEditButton">
                <i class="fas fa-edit text-grey" />
            </mercur-button>
             <template slot="label"><span>Edit attribute option</span></template>
        </mercur-tooltip>
        <mercur-tooltip>
            <mercur-button v-if="params.openAddAttributeOption" class="btn btn-icon btn-icon-sm" :disabled="isDisabled(params)" @click="openAddAttributeOption" data-test="attributeAddButton">
                <i class="fas fa-plus text-grey" />
            </mercur-button>
            <template slot="label"><span>Add new attribute option</span></template>
        </mercur-tooltip>
    </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
    name: 'AttributeTableActionCell',
    props: {
        params: {
            required: true,
        },
    },
    methods: {
        openAddAttributeOption () {
            this.params.openAddAttributeOption({
                attributeName: this.params.node.key,
                attributeKey: this.params.node.key,
            })
        },
        openEditAttributeOption () {
            this.params.openEditAttributeOption(this.params.data)
        },
        isDisabled (actionButton) {
            if (actionButton.disabled && {}.toString.call(actionButton.disabled) === '[object Function]') {
                return actionButton.disabled(this.params)
            }
            return actionButton.disabled
        },
    },
})
</script>

<style lang="scss" scoped>
    .actions {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: flex-end;
    }
</style>
