<template>
    <div>
        <mercur-item :to="getRouteForName('CarriersOverview')" v-if="isAllowedTo('getCarriers')">
            <i class="fas fa-truck"></i><span>Carriers</span>
        </mercur-item>
        <mercur-item :to="getRouteForName('ExtraDaysOverview')" v-if="isAllowedTo('getAllExtraDaysConfiguration')">
            <i class="fas fa-calendar-plus"></i><span>Extra days</span>
        </mercur-item>
        <!--<mercur-item :to="getRouteForName('HsCodesOverview')" v-if="isAllowedTo('HsCodesOverview')">
            <i class="fas fa-barcode"></i><span>HS codes</span>
        </mercur-item>-->
    </div>
</template>

<script>

export default {
    name: 'LogisticsSideMenu',
    methods: {
        getRouteForName (section, params) {
            return this.$router.resolve({
                name: section,
                params: {
                    ...params,
                },
            }).href
        },
    },
}
</script>
