import { SET_SUPPLIER_HOLIDAYS, SET_LOCATION_HOLIDAYS } from '@/store/mutation-types'
import CONFIG from '@root/config'

export default {
    namespaced: true,

    state: {
        holidays: {},
        locations: {},
    },

    mutations: {
        [ SET_SUPPLIER_HOLIDAYS ] (state, holidayDetails) {
            this._vm.$set(state.holidays, holidayDetails.supplierId, holidayDetails.holidays)
        },
        [ SET_LOCATION_HOLIDAYS ] (state, holidayDetails) {
            this._vm.$set(state.locations, holidayDetails.locationId, holidayDetails.holidays)
        },
    },

    actions: {
        fetchSupplierHolidays ({ dispatch, commit, state }, supplierId) {
            dispatch('loading/addJob', SET_SUPPLIER_HOLIDAYS, {
                root: true,
            })
            return new Promise((resolve, reject) => {
                const url = CONFIG.API.ROUTES.SUPPLIERS.HOLIDAYS.OVERVIEW.replace('{supplierId}', supplierId)

                this._vm.$api.get(url).then(({ data }) => {
                    commit(SET_SUPPLIER_HOLIDAYS, {
                        supplierId,
                        holidays: data.data.items,
                    })
                    resolve(data)
                }).catch((err) => {
                    if (CONFIG.DEBUG) {
                        console.error(err)
                    }
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', SET_SUPPLIER_HOLIDAYS, {
                        root: true,
                    })
                })
            })
        },

        fetchLocationHolidays ({ dispatch, commit, state }, { supplierId, locationId }) {
            dispatch('loading/addJob', SET_LOCATION_HOLIDAYS, {
                root: true,
            })
            return new Promise((resolve, reject) => {
                const url = CONFIG.API.ROUTES.SUPPLIERS.LOCATION_GET_HOLIDAYS.replace('{supplierId}', supplierId).replace('{locationId}', locationId)
                this._vm.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                    commit(SET_LOCATION_HOLIDAYS, {
                        locationId,
                        holidays: data.data.items,
                    })
                    resolve(data.data.items)
                }).catch((err) => {
                    if (CONFIG.DEBUG) {
                        console.error(err)
                    }
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', SET_LOCATION_HOLIDAYS, {
                        root: true,
                    })
                })
            })
        },
    },
    getters: {
        getHolidayBySupplierIdAndHolidayId (state) {
            return (supplierId, holidayId) => {
                if (!state.holidays[supplierId]) {
                    return null
                }

                return state.holidays[supplierId].find(holiday => holiday.holidayId === holidayId)
            }
        },
    },
}
