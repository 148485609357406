<template>
    <div class="notification">
        <div data-test="notificationItemWrapper" class="notification-child d-flex align-items-center justify-content-between">
            <i v-if="showIcon" class="mr-2" :class="'fas fa-'+icon"></i>
            <div data-test="notificationItemContent" @click="getLink(type, notification)" class="flex-grow-1">
                <span data-test="notificationTask" v-if="type === 'tasks'">
                    <small class="flex-grow-1">{{notification.title}} - {{notification.status}} </small>
                </span>
                <span data-test="notificationOrder" class="d-flex" v-if="type === 'orders'">
                    <small class="flex-grow-1">{{notification.orderNumber}} - {{notification.orderLineNumber}} </small>
                    <small class="text-grey" v-text="formatDate(notification.dateUpdated)"></small>
                </span>
                <span data-test="notificationComplaint" class="d-flex" v-if="type === 'complaints'">
                    <small class="flex-grow-1">{{notification.orderNumber}} - {{notification.orderLineNumber}} | {{notification.productName}}</small>
                    <small class="text-grey" v-text="formatDate(notification.dateUpdated)"></small>
                </span>
                <span data-test="notificationAlert" v-if="type === 'alerts'">
                    <small>{{notification.message}}</small>
                </span>
            </div>
            <mercur-button data-test="notificationItemDelete" v-if="deletable" class="btn btn-icon" @click="deleteNotification(0, type)"><i class="fas fa-times"></i></mercur-button>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
export default {
    name: 'NotificationItem',
    props: {
        type: String,
        notification: Object,
        showIcon: {
            type: Boolean,
            default: true,
        },
        deletable: {
            type: Boolean,
            default: true,
        },
        icon: String,
    },
    methods: {
        ...mapActions({ dismissNotification: 'notifications/dismissNotification' }),
        getLink (type, data) {
            switch (type) {
            case 'tasks': break
            case 'alerts': break
            case 'orders':
                this.$router.push({
                    name: 'SupplierHome',
                    params: {
                        supplierId: data.producer.supplierId,
                    },
                })
                break
            case 'complaints':
                this.$router.push({
                    name: 'ComplaintGlobalDetail',
                    params: {
                        complaintSection: 'suppliers',
                        complaintStatus: 'AWAITING_SUPPLIER',
                        complaintSupplierId: data.supplier.supplierId,
                        complaintId: data.complaintId,
                    },
                })
                break
            }
        },
        deleteNotification (index, type) {
            setTimeout(() => {
                this.dismissNotification({ index, type })
            }, 100)
        },
        formatDate (date) {
            return moment(date).format('DD/MM HH:MM')
        },
    },
}
</script>
