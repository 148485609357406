import CONFIG from '@root/config'

import {
    SET_SERVER_NOTIFICATIONS,
    UPDATE_NOTIFICATION,
    DISMISS_NOTIFICATION,
    ADD_NOTIFICATION_NONPERSISTENT,
    CLEAR_NOTIFICATIONS,
} from '@/store/mutation-types'

const FIVE_MIN = 5 * 60 * 1000
const NON_PERSISTENT_NOTIFICATION_TIMEOUT = FIVE_MIN
const DISSMISS_SHOW_NOTIFICATION_TIMEOUT = 3000
const MAX_NOTIFICATIONS_COUNT = 5

function removeNonPersistentNotification (commit, notifications) {
    const date = new Date()
    Object.keys(notifications).forEach((type) => {
        notifications[type].forEach((notification, index) => {
            if (notification && notification.nonPersistent && (date - new Date(notification.date)) > DISSMISS_SHOW_NOTIFICATION_TIMEOUT) {
                notification.show = false
                commit(UPDATE_NOTIFICATION, { type, notification })
            }
            if (notification && notification.nonPersistent && (date - new Date(notification.date)) > NON_PERSISTENT_NOTIFICATION_TIMEOUT) {
                commit(DISMISS_NOTIFICATION, { index, type })
            }
        })
    })
}

export default {
    namespaced: true,

    state: {
        notifications: {
            tasks: [],
            alerts: [],
            orders: [],
            complaints: [],
        },
    },

    mutations: {
        [ SET_SERVER_NOTIFICATIONS ] (state, notificationObject) {
            let auxNotifications = state.notifications
            auxNotifications[notificationObject.type] = notificationObject.notifications
            this._vm.$set(state, 'notifications', auxNotifications)
        },
        [ ADD_NOTIFICATION_NONPERSISTENT ] (state, notificationObject) {
            const auxNotifications = state.notifications

            if (auxNotifications[notificationObject.type].length === MAX_NOTIFICATIONS_COUNT) {
                auxNotifications[notificationObject.type].shift()
            }

            auxNotifications[notificationObject.type].push(notificationObject.notification)

            this._vm.$set(state, 'notifications', { ...auxNotifications })
        },
        [ UPDATE_NOTIFICATION ] (state, notificationObject) {
            const auxNotifications = state.notifications
            let auxTaskNotf = auxNotifications[notificationObject.type].findIndex(e => e === notificationObject.notification)
            if (auxTaskNotf >= 0) {
                auxNotifications[notificationObject.type][auxTaskNotf] = notificationObject.notification
                this._vm.$set(state, 'notifications', { ...auxNotifications })
            }
        },
        [ DISMISS_NOTIFICATION ] (state, notificationObject) {
            const auxNotifications = state.notifications
            notificationObject.index !== undefined ? auxNotifications[notificationObject.type].splice(notificationObject.index, 1) : auxNotifications[notificationObject.type] = []
            this._vm.$set(state, 'notifications', auxNotifications)
        },
        [ CLEAR_NOTIFICATIONS ] (state) {
            this._vm.$set(state, 'notifications', {
                tasks: [],
                alerts: [],
                orders: [],
                complaints: [],
            })
        },
    },
    actions: {
        fetchNotifications ({ dispatch, commit, state }) {
            dispatch('loading/addJob', SET_SERVER_NOTIFICATIONS, {
                root: true,
            })
            const currentUser = this._vm['auth/user']
            const isVirtualSupplier = currentUser.supplierType === 'VIRTUAL_SUPPLIER'

            let payload = {
                orderOrigin: 'cart',
                request: CONFIG.API.REQUEST_DEFAULT.request,
                complaintStatus: 'awaiting_supplier',
                orderLineStatus: 'approved',
            }

            if (isVirtualSupplier === true) {
                payload.isVirtualSupplier = true
                payload.orderLineOverviewType = 'orders'
                payload.complaintStatus = 'AWAITING_SUPPLIER'
                payload.complaintSection = 'suppliers'
            }

            const url = CONFIG.API.ROUTES.SUPPLIERS.NOTIFICATIONS.GET.replace('{supplierId}', currentUser.supplierId)
            this._vm.$api.post(url, payload).then(({ data }) => {
                let complaintsForUser = []
                if (isVirtualSupplier) {
                    complaintsForUser = data.data.complaints.items ? data.data.complaints.items.filter((c) => c.virtualSupplierComplaintNotes &&
                        c.virtualSupplierComplaintNotes[0] && c.virtualSupplierComplaintNotes[0].uploadedBy === currentUser.accountId) : []
                } else {
                    complaintsForUser = data.data.complaints.items ? data.data.complaints.items.filter((c) => c.supplier && c.supplier.supplierId === currentUser.supplierId) : []
                }
                complaintsForUser.forEach(c => { c.date = new Date() })
                commit(SET_SERVER_NOTIFICATIONS, { notifications: complaintsForUser, type: 'complaints' })

                let ordersForUser = data.data.orders.items ? data.data.orders.items.filter((c) => c.orderLineStatus && c.orderLineStatus === 'READY_FOR_PRODUCTION') : []
                ordersForUser.forEach(c => { c.date = new Date() })
                commit(SET_SERVER_NOTIFICATIONS, { notifications: ordersForUser, type: 'orders' })
            }).catch((err) => {
                if (CONFIG.DEBUG) {
                    console.error(err)
                }
                commit(SET_SERVER_NOTIFICATIONS, { notifications: [], type: 'complaints' })
                commit(SET_SERVER_NOTIFICATIONS, { notifications: [], type: 'orders' })
            }).finally(() => {
                dispatch('loading/finishJob', SET_SERVER_NOTIFICATIONS, {
                    root: true,
                })
            })
            setInterval(removeNonPersistentNotification, 5000, commit, state.notifications)
        },
        updateNotification ({ commit }, notificationObject) {
            if (notificationObject.notification && notificationObject.type) {
                commit(UPDATE_NOTIFICATION, notificationObject)
            }
        },
        dismissNotification ({ commit }, notificationObject) {
            if (notificationObject.type) {
                commit(DISMISS_NOTIFICATION, notificationObject)
            }
        },
        addNotificationNonPersistent ({ commit }, notificationObject) {
            if (notificationObject.notification && notificationObject.type) {
                notificationObject.notification.nonPersistent = true
                notificationObject.notification.date = new Date()
                if (notificationObject.type !== 'alerts') { notificationObject.notification.show = true }
                commit(ADD_NOTIFICATION_NONPERSISTENT, notificationObject)
            }
        },
        clearNotifications ({ commit }) {
            commit(CLEAR_NOTIFICATIONS, [])
        },
    },
    getters: {
        notifications (state) {
            return state.notifications
        },
        notificationsToDisplay (state) {
            let notificationsToDisplay = {}
            Object.keys(state.notifications).forEach((el) => {
                const itemsToDisplay = state.notifications[el].filter(notf => notf.show === true)
                if (itemsToDisplay && itemsToDisplay.length > 0) {
                    notificationsToDisplay[el] = itemsToDisplay
                }
            })
            return notificationsToDisplay
        },
        getNotificationIcon () {
            return (type) => {
                switch (type) {
                case 'tasks': return 'download'
                case 'orders': return 'list'
                case 'complaints': return 'thumbs-down'
                case 'alerts': return 'exclamation-triangle'
                }
            }
        },
    },

}
