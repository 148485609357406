import { SET_SUPPLIER_CONTACT_PERSONS, SET_LOCATION_CONTACT_PERSONS } from '@/store/mutation-types'
import CONFIG from '@root/config'

export default {
    namespaced: true,

    state: {
        contactPersons: {},
        locations: {},
    },

    mutations: {
        [ SET_SUPPLIER_CONTACT_PERSONS ] (state, contactPersonDetails) {
            this._vm.$set(state.contactPersons, contactPersonDetails.supplierId, {
                ...state.contactPersons[contactPersonDetails.supplierId],
                ...contactPersonDetails.contactPersons.reduce((obj, item) => {
                    obj[item.contactPersonId] = item
                    return obj
                }, {}),
            })
        },
        [ SET_LOCATION_CONTACT_PERSONS ] (state, contactPersonDetails) {
            this._vm.$set(state.locations, contactPersonDetails.locationId, contactPersonDetails.contactPersons)
        },
    },

    actions: {
        setSupplierContactPersons ({ dispatch, commit, state }, { supplierId, contactPersons }) {
            commit(SET_SUPPLIER_CONTACT_PERSONS, {
                supplierId,
                contactPersons,
            })
        },

        fetchSupplierContactPersonByContactPersonId ({ dispatch, commit, state }, { supplierId, contactPersonId }) {
            dispatch('loading/addJob', SET_SUPPLIER_CONTACT_PERSONS, {
                root: true,
            })
            return new Promise((resolve, reject) => {
                dispatch('fetchSupplierContactPersons', { supplierId }).then(data => {
                    const contactPerson = data.find(contactPerson => contactPerson.contactPersonId === contactPersonId)
                    resolve(contactPerson)
                    commit(SET_SUPPLIER_CONTACT_PERSONS, {
                        supplierId,
                        contactPersons: [contactPerson],
                    })
                }).catch((err) => {
                    if (CONFIG.DEBUG) {
                        console.error(err)
                    }
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', SET_SUPPLIER_CONTACT_PERSONS, {
                        root: true,
                    })
                })
            })
        },

        fetchSupplierContactPersons ({ dispatch, commit, state }, { supplierId }) {
            dispatch('loading/addJob', SET_SUPPLIER_CONTACT_PERSONS, {
                root: true,
            })
            return new Promise((resolve, reject) => {
                const url = CONFIG.API.ROUTES.SUPPLIERS.CONTACT_PERSONS.OVERVIEW.replace('{supplierId}', supplierId)

                this._vm.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                    commit(SET_SUPPLIER_CONTACT_PERSONS, {
                        supplierId,
                        contactPersons: data.data.items,
                    })
                    resolve(data.data.items)
                }).catch((err) => {
                    if (CONFIG.DEBUG) {
                        console.error(err)
                    }
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', SET_SUPPLIER_CONTACT_PERSONS, {
                        root: true,
                    })
                })
            })
        },
    },
    getters: {
        getContactPersonBySupplierIdAndContactPersonId (state) {
            return (supplierId, contactPersonId) => {
                if (!state.contactPersons[supplierId]) {
                    return null
                }

                return state.contactPersons[supplierId][contactPersonId]
            }
        },
        getContactPersonsBySupplierId (state) {
            return (supplierId) => {
                if (!state.contactPersons[supplierId]) {
                    return null
                }

                return Object.values(state.contactPersons[supplierId])
            }
        },
    },
}
