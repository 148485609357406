<template>
    <div>
        <router-link class="logo" :to="getHomeRoute()" :is="isAuthenticated ? 'router-link' : 'span'"><logo fill="white"></logo></router-link>
        <div class="top-menu" data-test="topNavigationMenu" v-if="isAuthenticated && !isSupplier && isVirtualSupplier">
            <router-link
                v-for="(menuItem, key) in menuItems"
                :key="key"
                :to="menuItem.route"
                v-bind="menuItem.attr"
                class="top-menu__link"
                :class="{'top-menu__link--is-active': isSectionActive(menuItem.section)}"
            >
                {{menuItem.title}}
            </router-link>
        </div>
    </div>
</template>

<script>
import Logo from '@/components/assets/Logo'
import { mapGetters } from 'vuex'

export default {
    name: 'TopMenu',
    components: { Logo },
    data () {
        return {
            staticItems: [
                {
                    title: 'Suppliers',
                    route: {
                        name: 'SuppliersView',
                    },
                    attr: {
                        'data-test': 'topNavigationSupplierMenuLink',
                    },
                    section: 'suppliers',
                },
                /* {
                    title: 'Orders',
                    route: {
                        name: 'OrdersView',
                    },
                    attr: {
                        'data-test': 'topNavigationOrdersMenuLink',
                    },
                    section: 'orders',
                }, */
                /* {
                    title: 'Complaints',
                    route: {
                        name: 'ComplaintsGlobalView',
                    },
                    section: 'complaints_global',
                }, */
                {
                    title: 'Logistics',
                    route: {
                        name: 'LogisticsOverview',
                    },
                    section: 'logistics',
                },
                {
                    title: 'Agreements',
                    route: {
                        name: 'AgreementsManagementView',
                    },
                    attr: {
                        'data-test': 'topNavigationAgreementsMenuLink',
                    },
                    section: 'agreements_management',
                },
                // {
                //     title: 'Legacy',
                //     route: {
                //         name: 'LegacyView',
                //     },
                //     section: 'legacy',
                // },
                /* {
                    title: 'Holidays',
                    route: {
                        name: 'GlobalHolidaysView',
                    },
                    section: 'global_holidays',
                }, */
                /* {
                    title: 'Quotes',
                    route: {
                        name: 'QuotesOverview',
                    },
                    attr: {
                        'data-test': 'topNavigationQuotesMenuLink',
                    },
                    section: 'quotes',
                }, */
                {
                    title: 'Access control',
                    route: {
                        name: 'AccessControlOverview',
                    },
                    section: 'access-control',
                },
                {
                    title: 'Product content',
                    route: {
                        name: 'ProductContentCategoriesView',
                    },
                    attr: {
                        'data-test': 'topNavigationProductContentManagementMenuLink',
                    },
                    section: 'product-content-management',
                },
                /* {
                    title: 'Artwork Management',
                    route: {
                        name: 'ArtworkManagementView',
                    },
                    section: 'artwork_management',
                }, */
            ],
        }
    },
    computed: {
        ...mapGetters('auth', [ 'isAuthenticated', 'user' ]),
        menuItems () {
            return [
                ...this.staticItems,
                {
                    title: 'Virtual Supplier Management',
                    route: {
                        name: 'SupplierProducts',
                        params: {
                            supplierId: this.user.supplierId,
                        },
                    },
                    section: 'virtual-supplier',
                },
            ]
        },
        isVirtualSupplier () {
            if (!this.user) {
                return false
            }

            return this.user.supplierType === 'VIRTUAL_SUPPLIER'
        },
    },
    methods: {
        isSectionActive (sectionName) {
            if (sectionName === 'virtual-supplier' && this.user.supplierType === 'VIRTUAL_SUPPLIER') {
                return this.$route.params.supplierId === this.user.supplierId
            }
            return this.$route.matched.some(route => {
                if (sectionName === 'suppliers' && this.$route.params.supplierId === this.user.supplierId) {
                    return false
                }

                return route.meta.section === sectionName
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .top-menu {
        padding-left: 10px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        &__link {
            color: white !important;
            text-transform: none;
            letter-spacing: 0;
            font-weight: 600;
            padding-right: 5px;
            padding-left: 5px;

            &--is-active {
                color: #ffc628 !important;
            }
        }
    }
    .logo {
        color: white !important;
        text-transform: none;
        font-weight: 600;
        margin-left: 15px;
        line-height: 14px;
    }
</style>
