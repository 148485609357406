<template>
    <div data-test="notificationPanelWrapper" v-click-outside="clickedOutside" class="notification-area">
        <div data-test="notificationPanelWrapperStatus" class="notification-area-status">
            <span data-test="notificationPanelTriggerOpenWrapper" @click="isNotificationsOpen = !isNotificationsOpen" class="far fa-envelope" >
                <mark v-if="notificationCount > 0" :class="showWobble ? 'wobble' : ''">{{notificationCount}}</mark>
            </span>
            <div v-show="isNotificationsOpen" class="triangle"></div>
        </div>
        <notification-popup @newAlert="wobbleNotification" />
        <div data-test="notificationPanelOpenWrapper" v-show="isNotificationsOpen" class="notification-area-list">
            <p class="my-1 p-2 text-center"><span v-text="(notificationCount > 0) ? 'Notifications':' No new notifications'" /></p>
            <template v-for="(notification, key, index) in notifications">
                <notification-item
                    v-if="notifications[key].length !== 0 && isSingleNotification(notifications[key])"
                    :key="index"
                    :type="key"
                    :icon="getIcon(key)"
                    :notification="notification[0]">
                </notification-item>
                <notification-group
                    v-else-if="notifications[key].length > 1"
                    :notificationsType="notification"
                    :notifications="notifications"
                    :type="key"
                    :icon="getIcon(key)"
                    :expandedItem.sync="expandedItem"
                    :key="index">
                </notification-group>
            </template>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import NotificationItem from './notification/NotificationItem'
import NotificationPopup from './NotificationPopup'
import NotificationGroup from './notification/NotificationGroup'
export default {
    name: 'Notifications',
    components: {
        NotificationItem,
        NotificationPopup,
        NotificationGroup,
    },
    data: () => {
        return {
            isNotificationsOpen: false,
            showWobble: false,
            expandedItem: '',
        }
    },
    computed: {
        ...mapState('notifications', ['notifications']),
        notificationCount () {
            let number = 0
            for (var item in this.notifications) {
                number += this.notifications[item].length
            }
            return number
        },
    },
    methods: {
        ...mapActions({ dismissNotification: 'notifications/dismissNotification' }),
        getIcon (type) {
            return this.$store.getters['notifications/getNotificationIcon'](type)
        },
        clickedOutside () {
            if (this.isNotificationsOpen) {
                this.isNotificationsOpen = !this.isNotificationsOpen
            }
        },
        isSingleNotification (notifications) {
            if (notifications.length && notifications.length === 1) {
                return true
            }
            return false
        },

        wobbleNotification () {
            this.showWobble = true
            setTimeout(() => {
                this.showWobble = false
            }, 1000)
        },
    },
}
</script>

<style lang="scss">
.btn {
    &--notification {
        font-size: 12px;
        letter-spacing: 0;
        text-transform: initial;
        margin-right: 10px;
        i {
            color: #ea5b01 !important;
            font-size: 18px;
        }
    }
}
.notification-area {
    .triangle {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #fff;
        position: absolute;
        bottom: 2px;
        margin: auto;
        left: 0;
        right: 0;
    }
    &-list {
        position: fixed;
        z-index: 9999;
        right: 5px;
        width: 450px;
        padding: 0.2rem !important;
        background-color: #fff !important;
        margin-top: -2px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        .notification {
            cursor: pointer;
            border-bottom: 1px solid #dee2e6;
            &:last-child {
                margin-bottom: 0;
                border-bottom: 0;
            }
            .btn-icon {
                margin: 5px;
                width: 25px;
                height: 25px;
                padding:0;
                i {
                    font-size: 13px;
                }
            }
            &-child {
                width: 100%;
                padding-left: 1rem;
                background-color: #f7f7f8;
                button {
                    opacity: 0;
                }
                &:hover {
                    background-color: #dee2e6;
                    button {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &-status * {
        box-sizing: content-box;
        list-style-type: none;
    }

    &-status:hover {
        color: #ffc628;
        cursor: pointer;
    }

    &-status  {
        font-size: 20px;
        position: relative;
        width: 100%;
        padding: 10px 0;
        list-style-type: circle;
    }

    &-status span {
        display: block;
        border-radius: 20px;
        color: #fff;
        background-color: #ffffff09;
        padding: 10px;
        width: 20px;
        height: 20px;
        margin: 0 auto;
        line-height: 20px;
        text-align: center;
        position: relative;
    }

    &-status mark {
        border-radius: 20px;
        font-family: Arial, Helvetica, sans-serif;
        width: 20px;
        height: 20px;
        position: absolute;
        top: -5px;
        left: -10px;
        font-size: 10px;
        line-height: 20px;
        font-weight: 400;
        font-weight: 700;
        background-color: #ffbc02;
    }

    &-status  > span > mark {
        animation-name: bounceIn;
        transform-origin: center bottom;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-iteration-count: 1;
    }

    &-status > span > mark.wobble {
        animation-name: wobble;
    }

}
.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
