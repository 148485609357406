import suppliers from './suppliers'
import productAttributes from './productattributes'
import auth from './auth'
import loading from './loading'
import products from './products'
import carriers from './carriers'
import holidays from './holidays'
import contactPersons from './contactPersons'
import tasks from './tasks'
import finance from './finance'
import productContent from './productContent'
import notifications from './notifications'

export default {
    auth,
    suppliers,
    productAttributes,
    products,
    carriers,
    holidays,
    contactPersons,
    loading,
    tasks,
    finance,
    productContent,
    notifications,
}
