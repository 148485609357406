<template>
    <div>
        <mercur-item v-if="isAllowedTo('SupplierCentral/listComplaintsForVirtualSupplier')" :expand="true">
            <i class="fas fa-users"></i><span>Supplier</span>
            <template slot="content">
                <mercur-item
                    v-for="(status, title) in menuData.suppliers"
                    :key="status"
                    :to="getNestedRoute('ComplaintsGlobalOverview', { complaintSection: 'suppliers', complaintStatus: status })">
                    <i class="fas fa-list"></i><span>{{title}}</span>
                </mercur-item>
            </template>
        </mercur-item>
        <mercur-item v-if="isAllowedTo('SupplierCentral/listComplaintsForVirtualSupplier')" :expand="true">
            <i class="fas fa-truck-moving"></i><span>Carriers</span>
            <template slot="content">
                <mercur-item
                    v-for="(status, title) in menuData.carriers"
                    :key="status"
                    :to="getNestedRoute('ComplaintsGlobalOverview', { complaintSection: 'carriers', complaintStatus: status })">
                    <i class="fas fa-list"></i><span>{{title}}</span>
                </mercur-item>
            </template>
        </mercur-item>
        <mercur-item v-if="isAllowedTo('SupplierCentral/listComplaintsForVirtualSupplier')" :expand="true">
            <i class="fas fa-palette"></i><span>Artwork</span>
            <template slot="content">
                <mercur-item
                    v-for="(status, title) in menuData.artwork"
                    :key="status"
                    :to="getNestedRoute('ComplaintsGlobalOverview', { complaintSection: 'artwork', complaintStatus: status })">
                    <i class="fas fa-list"></i><span>{{title}}</span>
                </mercur-item>
            </template>
        </mercur-item>
        <mercur-item v-if="isAllowedTo('SupplierCentral/listComplaintsForVirtualSupplier')" :expand="true">
            <i class="fas fa-link"></i><span>Supply Chain</span>
            <template slot="content">
                <mercur-item
                    v-for="(status, title) in menuData.supplyChain"
                    :key="status"
                    :to="getNestedRoute('ComplaintsGlobalOverview', { complaintSection: 'supplyChain', complaintStatus: status })">
                    <i class="fas fa-list"></i><span>{{title}}</span>
                </mercur-item>
            </template>
        </mercur-item>
    </div>
</template>

<script>

export default {
    name: 'GlobalComplaintsSideMenu',
    data () {
        return {
            menuData: {
                'suppliers': {
                    'Awaiting supplier': 'AWAITING_SUPPLIER',
                    'Rejected': 'REJECTED',
                    'Approved': 'APPROVED',
                    'Awaiting Merchant': 'AWAITING_CUSTOMER_SERVICE',
                },
                'carriers': {
                    // Not available in Mercur yet
                    // 'Awaiting carrier': 'AWAITING_CARRIER',
                    'Awaiting supplier': 'AWAITING_SUPPLIER',
                    'Approved': 'APPROVED',
                    'Awaiting Merchant': 'AWAITING_CUSTOMER_SERVICE',
                },
                'artwork': {
                    // Not available in Mercur yet
                    // 'Awaiting artwork': 'AWAITING_ARTWORK',
                    'Awaiting supplier': 'AWAITING_SUPPLIER',
                    'Approved': 'APPROVED',
                    'Awaiting Merchant': 'AWAITING_CUSTOMER_SERVICE',
                },
                'supplyChain': {
                    // Not available in Mercur yet
                    // 'Awaiting supply chain': 'AWAITING_SUPPLY_CHAIN',
                    'Awaiting supplier': 'AWAITING_SUPPLIER',
                    'Approved': 'APPROVED',
                    'Awaiting Merchant': 'AWAITING_CUSTOMER_SERVICE',
                },
            },
        }
    },
    methods: {
        getNestedRoute (section, params) {
            return this.$router.resolve({
                name: section,
                params: {
                    ...params,
                },
            }).href
        },
    },
}
</script>
