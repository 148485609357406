<template>
    <div>
        <span class="p-3 d-block">All quotes</span>
        <mercur-item
            v-for="(quoteStatus, key) in quotesStatuses"
            :key="key"
            :to="getRouteForName('QuotesView', {status: quoteStatus.value})">
            <i :class="quoteStatus.icon"></i><span>{{quoteStatus.title}}</span>
        </mercur-item>

        <span class="p-3 d-block">My quotes</span>
        <mercur-item
            v-for="(quoteStatus, key) in quotesStatuses"
            :key="`my-quotes_${key}`"
            :to="getRouteForName('QuotesView', {
                status: quoteStatus.value,
                quoteAssigneeId: $store.state.auth.user.accountId,
            })">
            <i :class="quoteStatus.icon"></i><span>{{quoteStatus.title}}</span>
        </mercur-item>
    </div>
</template>

<script>
import CONFIG from '@root/config'

export default {
    name: 'QuotesSideMenu',
    data () {
        return {
            quotesStatuses: CONFIG.STATUSSES.QUOTES,
        }
    },
    methods: {
        getRouteForName (section, params) {
            return this.$router.resolve({
                name: section,
                params: {
                    ...params,
                },
            }).href
        },
    },
}
</script>
