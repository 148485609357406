<template>
    <div class="notification-popup">
        <transition-group data-test="notificationPopupWrapper" mode="out-in" name="slide-fade" tag="ul">
            <template v-for="(notifications, key, index) in alertNotifications">
                <div v-for="(notification, i) in notifications"
                    class="notification-popup-message d-flex align-items-center mb-1"
                    :class="'type-'+notification.type"
                    :key="i">
                    <notification-item
                        data-test="notificationPopupItem"
                        :deletable="false"
                        :key="index"
                        :type="key"
                        :icon="getIcon(key)"
                        :notification="notification">
                    </notification-item>
                </div>
            </template>
        </transition-group>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NotificationItem from './notification/NotificationItem'
export default {
    data: () => {
        return {
            alertNotifications: {},
        }
    },
    components: {
        NotificationItem,
    },
    computed: {
        ...mapGetters('notifications', ['notificationsToDisplay']),
    },
    methods: {
        getIcon (type) {
            return this.$store.getters['notifications/getNotificationIcon'](type)
        },
    },
    watch: {
        notificationsToDisplay (newVal) {
            this.$emit('newAlert', true)
            this.alertNotifications = newVal
        },
    },
}
</script>

<style lang="scss">
.notification-popup {
    position: absolute;
    right: 0;
    width: 450px;
    &-message {
        background-color: #fff;
        padding: 0.2rem 0.2rem 0.2rem 0.8rem;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        min-height: 35px;
        cursor: pointer;
    }
}
.slide-fade-enter-active {
  transition: all .2s ease;
}
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to  {
  transform: translateX(5px);
  opacity: 0;
}
</style>
