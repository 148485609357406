<template>
    <div class="ml-1" v-if="shouldShowAccountMenu">
        <div class="d-flex align-items-center ml-1" v-if="isAuthenticated">
            <notifications-panel />
            <div class="d-flex align-items-center ml-3">
                <span class="button-text mr-1">{{loginName}}</span>
                <mercur-menu data-test="userMenu">
                    <mercur-button class="btn btn-icon btn-primary">
                        <span class="text-white"><i class="fas fa-ellipsis-v"></i></span>
                    </mercur-button>
                    <div slot="dropdown">
                        <mercur-item data-test="logout" @click.native="logoutUser">Logout</mercur-item>
                    </div>
                </mercur-menu>
            </div>
        </div>
        <mercur-button class="btn btn-secondary" v-else :to="{'name': 'LoginView'}">
            Login
        </mercur-button>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import NotificationsPanel from './notification_center/NotificationsPanel'

export default {
    name: 'AccountMenu',
    components: { NotificationsPanel },
    methods: {
        ...mapActions('auth', ['logout']),
        logoutUser () {
            this.logout().then(() => {
                this.$router.push({
                    name: 'LoginView',
                })
            })
        },
    },
    computed: {
        ...mapState('auth', [ 'user' ]),
        ...mapGetters('auth', [ 'isAuthenticated' ]),
        loginName () {
            return this.user.fullName === this.user.supplierName ? this.user.fullName : `${this.user.fullName} - ${this.user.supplierName}`
        },
        shouldShowAccountMenu () {
            return this.$route.name !== 'QuoteProposalView'
        },
    },
}
</script>

<style lang="scss" scoped>
    .button-text {
        text-transform: none;
        display: inline-block;
        margin-top: -1px;
        color: white;
    }
</style>
