import { ADD_JOB, FINISH_JOB } from '@/store/mutation-types'

export default {
    namespaced: true,

    state: {
        pending: [],
    },

    mutations: {
        [ ADD_JOB ] (state, job) {
            state.pending.push(job)
        },

        [ FINISH_JOB ] (state, finishedJob) {
            this._vm.$set(state, 'pending', state.pending.filter((job) => finishedJob !== job))
        },
    },

    actions: {
        addJob ({ commit }, job) {
            commit(ADD_JOB, job)
        },

        finishJob ({ commit }, job) {
            commit(FINISH_JOB, job)
        },
    },
    getters: {
        getPendingJobs (state) {
            return state.pending
        },

    },
}
