import { SET_PRODUCT_DETAILS, SET_ARTWORK_FILES } from '@/store/mutation-types'
import CONFIG from '@root/config'

const SET_PRODUCT_TEMPLATES = 'SET_PRODUCT_TEMPLATES'

export default {
    namespaced: true,

    state: {
        products: {},
        artworkFiles: [],
        productTemplates: [],
    },

    mutations: {
        [ SET_PRODUCT_DETAILS ] (state, productDetails) {
            this._vm.$set(state.products, `${productDetails.supplierId}_${productDetails.productConfigurationId}`, productDetails.productDetails)
        },
        [ SET_ARTWORK_FILES ] (state, artworkFiles) {
            this._vm.$set(state, 'artworkFiles', artworkFiles)
        },
        [ SET_PRODUCT_TEMPLATES ] (state, productTemplates) {
            this._vm.$set(state, 'productTemplates', productTemplates)
        },
    },

    actions: {
        getProductDetailsForSupplierAndProductConfigurationId ({ dispatch, commit, state }, params) {
            dispatch('loading/addJob', SET_PRODUCT_DETAILS, {
                root: true,
            })
            return new Promise((resolve, reject) => {
                const url = CONFIG.API.ROUTES.PRODUCTS.GET.replace('{supplierId}', params.supplierId).replace('{productConfigurationId}', params.productConfigurationId)

                this._vm.$api.get(url).then(({ data }) => {
                    commit(SET_PRODUCT_DETAILS, {
                        supplierId: params.supplierId,
                        productConfigurationId: data.data.productConfigurationId,
                        productDetails: data.data,
                    })
                    resolve(data.data)
                }).catch((err) => {
                    if (CONFIG.DEBUG) {
                        console.error(err)
                    }
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', SET_PRODUCT_DETAILS, {
                        root: true,
                    })
                })
            })
        },

        getArtworkFiles ({ dispatch, commit, state }) {
            dispatch('loading/addJob', SET_ARTWORK_FILES, {
                root: true,
            })
            return new Promise((resolve, reject) => {
                const url = CONFIG.API.ROUTES.ARTWORK.FILES.OVERVIEW

                this._vm.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                    commit(SET_ARTWORK_FILES, data.data.items)
                    resolve(data.data.items)
                }).catch((err) => {
                    if (CONFIG.DEBUG) {
                        console.error(err)
                    }
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', SET_ARTWORK_FILES, {
                        root: true,
                    })
                })
            })
        },
        getProductTemplates ({ dispatch, commit, rootState }) {
            dispatch('loading/addJob', SET_PRODUCT_TEMPLATES, {
                root: true,
            })

            return new Promise((resolve, reject) => {
                const url = CONFIG.API.ROUTES.SUPPLIERS.PRODUCTS.replace('{supplierId}', rootState.auth.user.parentId || rootState.auth.user.supplierId)

                this._vm.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                    commit(SET_PRODUCT_TEMPLATES, data.data.items)
                    resolve(data.data.items)
                }).catch((err) => {
                    if (CONFIG.DEBUG) {
                        console.error(err)
                    }
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', SET_PRODUCT_TEMPLATES, {
                        root: true,
                    })
                })
            })
        },
    },
    getters: {
        getProductAttributes (state) {
            return state.productAttributes
        },
    },
}
