import Router from 'vue-router'

function load (view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)
}

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'HomeView',
            redirect: '/suppliers',
        },
        {
            path: '/login',
            name: 'LoginView',
            component: load('LoginView'),
            meta: {
                unguarded: true,
            },
        },
        {
            path: '/password-recover/:recoveryToken',
            name: 'PasswordRecover',
            component: load('PasswordRecoverView'),
            meta: {
                unguarded: true,
            },
        },
        {
            path: '/attributes',
            meta: {
                section: 'attributes',
            },
            component: load('AttributesView'),
            children: [
                {
                    name: 'AttributesOverview',
                    path: '',
                    component: load('attributes/AttributesOverview'),
                    props: {
                        breadcrumbElement: {
                            name: 'Attributes',
                        },
                    },
                },
                {
                    name: 'AttributesDetail',
                    path: 'edit/:attributeName',
                    component: load('attributes/AttributesDetail'),
                },
            ],
        },
        {
            path: '/suppliers',
            name: 'SuppliersView',
            component: load('SuppliersView'),
            meta: {
                section: 'suppliers',
            },
            breadcrumbElement: {
                name: 'Suppliers',
            },
        },
        {
            path: '/supplier/:supplierId([a-f0-9]{8}-?[a-f0-9]{4}-?4[a-f0-9]{3}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12})?',
            alias: 'SupplierView',
            meta: {
                section: 'suppliers',
                supplierUnguarded: true,
            },
            component: load('SupplierView'),
            children: [
                {
                    name: 'SupplierView',
                    path: '',
                    component: load('supplier/SupplierDetail'),
                },
                {
                    path: 'onboard',
                    name: 'SupplierOnboardView',
                    component: load('SupplierOnboardView'),
                },
                {
                    path: 'edit',
                    name: 'SupplierEdit',
                    component: load('supplier/SupplierEdit'),
                },
                {
                    path: 'cut-off-times',
                    component: load('CutOffTimesView'),
                    meta: {
                        section: 'cut_off_times',
                    },
                    props: {
                        breadcrumbElement: {
                            name: 'Cut Off times',
                        },
                    },
                    children: [
                        {
                            path: '',
                            component: load('cut_off_times/CutOffTimesOverview'),
                            name: 'CutOffTimesOverview',
                        },
                    ],
                },
                {
                    path: 'orderlines',
                    redirect: 'orderlines/status/approved',
                    name: 'SupplierHome',
                    meta: {
                        supplierUnguarded: true,
                    },
                },
                {
                    path: 'orderlines/status/:status?',
                    name: 'SupplierOrderlines',
                    component: load('supplier/SupplierOrderlines'),
                    props: {
                        breadcrumbElement: {
                            name: 'Orders',
                        },
                    },
                    meta: {
                        sidebarSize: 'mini',
                        supplierUnguarded: true,
                        sideMenuSection: 'orders',
                    },
                    children: [
                        {
                            path: 'orderline/:orderId/:orderLineId',
                            name: 'OrderLineDetail',
                            component: load('supplier/orderlines/OrderLineDetail'),
                            meta: {
                                sidebarSize: 'mini',
                                rightSideBar: true,
                                supplierUnguarded: true,
                                sideMenuSection: 'orders',
                            },
                        },
                    ],
                },
                {
                    path: 'orderlines/history',
                    name: 'SupplierOrderlinesHistory',
                    component: load('supplier/SupplierOrderlinesHistory'),
                    props: {
                        breadcrumbElement: {
                            name: 'History',
                        },
                    },
                    meta: {
                        sidebarSize: 'mini',
                        supplierUnguarded: true,
                        sideMenuSection: 'orders',
                    },
                },
                {
                    path: 'quotes-orderlines',
                    redirect: 'quotes-orderlines/status/approved',
                    meta: {
                        supplierUnguarded: true,
                    },
                },
                {
                    path: 'quotes-orderlines/status/:status?',
                    name: 'SupplierQuoteOrderlines',
                    component: load('supplier/SupplierOrderlines'),
                    props: {
                        breadcrumbElement: {
                            name: 'Orders',
                        },
                        orderOrigin: 'quote',
                    },
                    meta: {
                        sidebarSize: 'mini',
                        supplierUnguarded: true,
                        sideMenuSection: 'quotes-orders',
                    },
                    children: [
                        {
                            path: 'quotes-orderlines/:orderId/:orderLineId',
                            name: 'QuoteOrderLineDetail',
                            component: load('supplier/orderlines/OrderLineDetail'),
                            meta: {
                                sidebarSize: 'mini',
                                rightSideBar: true,
                                supplierUnguarded: true,
                                sideMenuSection: 'orders',
                            },
                        },
                    ],
                },
                {
                    path: 'quotes-orderlines/history',
                    name: 'SupplierQuoteOrderlinesHistory',
                    component: load('supplier/SupplierOrderlinesHistory'),
                    props: {
                        breadcrumbElement: {
                            name: 'History',
                        },
                    },
                    meta: {
                        sidebarSize: 'mini',
                        supplierUnguarded: true,
                        sideMenuSection: 'quotes-orders',
                    },
                },
                {
                    path: 'invoices',
                    component: load('supplier/SupplierInvoices'),
                    props: {
                        breadcrumbElement: {
                            name: 'Invoices',
                        },
                    },
                    children: [
                        {
                            name: 'SupplierInvoices',
                            path: 'overview',
                            component: load('supplier/invoices/InvoicesOverview'),
                        },
                        {
                            name: 'ToCreditInvoices',
                            path: 'to-credit',
                            component: load('supplier/complaints/ComplaintsOverview'),
                            children: [
                                {
                                    path: 'complaint/:complaintId',
                                    name: 'ToCreditInvoiceComplaintDetail',
                                    component: load('supplier/complaints/ComplaintDetail'),
                                    meta: {
                                        sidebarSize: 'mini',
                                        rightSideBar: true,
                                        supplierUnguarded: true,
                                        sideMenuSection: 'complaints',
                                    },
                                    props: (route) => {
                                        return {
                                            ...route.params,
                                            complaintSupplierId: route.params.supplierId,
                                        }
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'package-configuration',
                    name: 'SupplierPackageConfigurations',
                    component: load('supplier/SupplierPackageConfigurations'),
                    props: {
                        breadcrumbElement: {
                            name: 'Package configuration',
                        },
                    },
                },
                {
                    path: 'holidays',
                    component: load('supplier/SupplierHolidays'),
                    props: {
                        breadcrumbElement: {
                            name: 'Holidays',
                        },
                    },
                    children: [
                        {
                            name: 'SupplierHolidays',
                            path: '',
                            component: load('supplier/holidays/HolidaysOverview'),
                        },
                        {
                            path: 'add',
                            name: 'SupplierAddHoliday',
                            component: load('supplier/holidays/HolidayAddEdit'),
                            props: {
                                breadcrumbElement: {
                                    name: 'Add holiday',
                                },
                            },
                        },
                        {
                            path: 'edit/:holidayId?',
                            name: 'SupplierEditHoliday',
                            component: load('supplier/holidays/HolidayAddEdit'),
                            props: {
                                breadcrumbElement: {
                                    name: 'Edit Holiday',
                                },
                            },
                        },
                    ],
                },
                {
                    path: 'contact-persons',
                    component: load('supplier/SupplierContactPersons'),
                    props: {
                        breadcrumbElement: {
                            name: 'Contact Persons',
                        },
                    },
                    children: [
                        {
                            name: 'SupplierContactPersons',
                            path: '',
                            component: load('supplier/contact_persons/ContactPersonsOverview'),
                        },
                        {
                            path: 'add',
                            name: 'SupplierAddContactPerson',
                            component: load('supplier/contact_persons/ContactPersonAddEdit'),
                            props: {
                                breadcrumbElement: {
                                    name: 'Add Contact Person',
                                },
                            },
                        },
                        {
                            path: 'edit/:contactPersonId?',
                            name: 'SupplierEditContactPerson',
                            component: load('supplier/contact_persons/ContactPersonAddEdit'),
                            props: {
                                breadcrumbElement: {
                                    name: 'Edit Contact Person',
                                },
                            },
                        },
                    ],
                },
                {
                    path: 'users',
                    component: load('supplier/SupplierUsers'),
                    props: {
                        breadcrumbElement: {
                            name: 'Users',
                        },
                    },
                    children: [
                        {
                            name: 'SupplierUsers',
                            path: '',
                            component: load('supplier/users/UsersOverview'),
                        },
                        {
                            path: 'add',
                            name: 'SupplierAddUser',
                            component: load('supplier/users/UserAddEdit'),
                            props: {
                                breadcrumbElement: {
                                    name: 'Add Supplier User',
                                },
                            },
                        },
                        {
                            path: 'edit/:userId?',
                            name: 'SupplierEditUser',
                            component: load('supplier/users/UserAddEdit'),
                            props: {
                                breadcrumbElement: {
                                    name: 'Edit Supplier User',
                                },
                            },
                        },
                    ],
                },
                {
                    path: 'templates',
                    component: load('supplier/SupplierTemplates'),
                    props: {
                        breadcrumbElement: {
                            name: 'Templates',
                        },
                    },
                    children: [
                        {
                            name: 'SupplierTemplates',
                            path: ':templateSection',
                            component: load('supplier/templates/TemplatesOverview'),
                        },
                    ],
                },
                {
                    path: 'imported-products',
                    component: load('supplier/SupplierImportedProducts'),
                    props: {
                        breadcrumbElement: {
                            name: 'Imported Products',
                        },
                    },
                    meta: {
                        sideMenuSection: 'products',
                    },
                    children: [
                        {
                            name: 'SupplierImportedProducts',
                            path: '/',
                            component: load('supplier/imported_products/ImportedProductsOverview'),
                        },
                        {
                            path: ':supplierProductImportedId',
                            component: load('supplier/imported_products/SupplierImportedProductView'),
                            props: true,
                            children: [
                                {
                                    name: 'SupplierImportedProductAdd',
                                    path: 'add',
                                    component: load('supplier/imported_products/AddEditProductMappingTemplate'),
                                    props: true,
                                },
                                {
                                    name: 'SupplierImportedProductEdit',
                                    path: 'edit',
                                    component: load('supplier/imported_products/AddEditProductMappingTemplate'),
                                    props: true,
                                },
                                {
                                    name: 'SupplierImportedProductUpload',
                                    path: 'upload',
                                    component: load('supplier/imported_products/SupplierImportedProductUpload'),
                                    props: true,
                                },
                                {
                                    name: 'SupplierImportedProductCsvFileColumnMapping',
                                    path: 'mapping',
                                    component: load('supplier/imported_products/SupplierImportedProductCsvFileColumnMapping'),
                                    props: true,
                                },
                                {
                                    name: 'SupplierImportedProductCsvFileValueMapping',
                                    path: 'value-mapping',
                                    component: load('supplier/imported_products/SupplierImportedProductCsvFileValueMapping'),
                                    props: true,
                                },
                                {
                                    name: 'SupplierImportedProductFacilities',
                                    path: 'facilities',
                                    component: load('supplier/imported_products/SupplierImportedProductFacilities'),
                                },
                                {
                                    name: 'SupplierImportedProductPackages',
                                    path: 'packages',
                                    component: load('supplier/imported_products/SupplierImportedProductPackages'),
                                },
                                {
                                    name: 'SupplierImportedProductCutoff',
                                    path: 'cutoff',
                                    component: load('supplier/imported_products/SupplierImportedProductCutoff'),
                                },
                                {
                                    name: 'SupplierImportedProductArtwork',
                                    path: 'artwork',
                                    component: load('supplier/imported_products/SupplierImportedProductArtwork'),
                                },
                                {
                                    name: 'SupplierImportedProductConfirmation',
                                    path: 'confirmation',
                                    component: load('supplier/imported_products/SupplierImportedProductConfirmation'),
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'attribute-mappings',
                    component: load('supplier/SupplierAttributeMappings'),
                    props: {
                        breadcrumbElement: {
                            name: 'Attribute Mappings',
                        },
                    },
                    meta: {
                        sideMenuSection: 'products',
                    },
                    children: [
                        {
                            name: 'SupplierAttributeMappings',
                            path: '/',
                            component: load('supplier/attribute_mappings/AttributeMappingsOverview'),
                        },
                        {
                            name: 'AddSupplierAttributeMapping',
                            path: 'add',
                            component: load('supplier/attribute_mappings/AddEditAttributeMapping'),
                        },
                        {
                            name: 'EditSupplierAttributeMapping',
                            path: ':supplierAttributeMappingId',
                            component: load('supplier/attribute_mappings/AddEditAttributeMapping'),
                        },
                    ],
                },
                {
                    path: 'complaints',
                    component: load('supplier/SupplierComplaints'),
                    props: {
                        breadcrumbElement: {
                            name: 'Complaints',
                        },
                    },
                    meta: {
                        sidebarSize: 'mini',
                        supplierUnguarded: true,
                        sideMenuSection: 'complaints',
                    },
                    children: [
                        {
                            name: 'SupplierComplaints',
                            path: ':status',
                            component: load('supplier/complaints/ComplaintsOverview'),
                            meta: {
                                sidebarSize: 'mini',
                                supplierUnguarded: true,
                                sideMenuSection: 'complaints',
                            },
                            children: [
                                {
                                    path: 'complaint/:complaintId',
                                    name: 'ComplaintDetail',
                                    component: load('supplier/complaints/ComplaintDetail'),
                                    meta: {
                                        sidebarSize: 'mini',
                                        rightSideBar: true,
                                        supplierUnguarded: true,
                                        sideMenuSection: 'complaints',
                                    },
                                    props: (route) => {
                                        return {
                                            ...route.params,
                                            complaintSupplierId: route.params.supplierId,
                                        }
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'locations',
                    component: load('supplier/SupplierLocations'),
                    props: {
                        breadcrumbElement: {
                            name: 'Locations',
                        },
                    },
                    children: [
                        {
                            name: 'SupplierLocations',
                            path: '',
                            component: load('supplier/locations/LocationsOverview'),
                        },
                        {
                            path: 'add-office',
                            component: load('SupplierAddOfficeView'),
                            props: {
                                breadcrumbElement: {
                                    name: 'Add office',
                                },
                            },
                            meta: {
                                constrainWidth: true,
                            },
                        },
                        {
                            path: ':officeId/add-facility',
                            component: load('SupplierAddFacilityView'),
                            props: {
                                breadcrumbElement: {
                                    name: 'Add facility',
                                },
                            },
                        },
                        {
                            path: ':facilityId/edit-facility',
                            component: load('SupplierAddFacilityView'),
                            name: 'SupplierEditFacility',
                            props: {
                                breadcrumbElement: {
                                    name: 'Edit facility',
                                },
                            },
                        },
                        {
                            path: ':officeId/edit-office',
                            component: load('SupplierAddOfficeView'),
                            name: 'SupplierEditOffice',
                            props: {
                                breadcrumbElement: {
                                    name: 'Edit office',
                                },
                            },
                        },
                        {
                            path: ':locationId/edit-holidays',
                            component: load('supplier/locations/SupplierEditLocationHolidays'),
                            name: 'SupplierEditLocationHolidays',
                            props: {
                                breadcrumbElement: {
                                    name: 'Edit holidays',
                                },
                            },
                        },
                        {
                            path: ':locationId/edit-contact-persons',
                            component: load('supplier/locations/SupplierEditLocationContactPersons'),
                            name: 'SupplierEditLocationContactPersons',
                            props: {
                                breadcrumbElement: {
                                    name: 'Edit contact persons',
                                },
                            },
                        },
                    ],
                },
                {
                    path: 'products',
                    component: load('supplier/SupplierProducts'),
                    props: {
                        breadcrumbElement: {
                            name: 'Products',
                        },
                    },
                    meta: {
                        sideMenuSection: 'products',
                    },
                    children: [
                        {
                            name: 'SupplierProducts',
                            path: '',
                            component: load('supplier/products/ProductsOverview'),
                        },
                        {
                            name: 'SupplierProductsAdd',
                            path: 'add/:productAddStep?',
                            component: load('supplier/products/SupplierProductsAdd'),
                            props: {
                                breadcrumbElement: {
                                    name: 'Add',
                                },
                            },
                            meta: {
                                sidebarSize: 'mini',
                            },
                        },
                        {
                            path: ':productConfigurationId',
                            component: load('supplier/products/ProductView'),
                            props: {
                                breadcrumbElement: {
                                    name: '...',
                                },
                            },
                            children: [
                                {
                                    name: 'SupplierProductView',
                                    path: '',
                                    component: load('supplier/products/ProductDetail'),
                                },
                                {
                                    name: 'SupplierProductVariationsView',
                                    path: 'variations',
                                    component: load('supplier/products/ProductVariationsView'),
                                    props: {
                                        breadcrumbElement: {
                                            name: 'Variations',
                                        },
                                    },
                                    meta: {
                                        sidebarSize: 'mini',
                                    },
                                },
                                {
                                    name: 'SupplierProductPackagesView',
                                    path: 'packages',
                                    component: load('supplier/products/ProductPackagesView'),
                                    props: {
                                        breadcrumbElement: {
                                            name: 'Packages',
                                        },
                                    },
                                },
                                {
                                    name: 'SupplierProductTemplatesView',
                                    path: 'templates/:templateSection',
                                    component: load('supplier/products/ProductTemplatesView'),
                                    props: {
                                        breadcrumbElement: {
                                            name: 'Templates',
                                        },
                                    },
                                },
                                {
                                    name: 'SupplierProductsEdit',
                                    path: 'edit/:productAddStep?',
                                    component: load('supplier/products/SupplierProductsAdd'),
                                    props: {
                                        breadcrumbElement: {
                                            name: 'edit',
                                        },
                                    },
                                    meta: {
                                        sidebarSize: 'mini',
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'templates-for-approval',
                    component: load('supplier/SupplierTemplatesForApproval'),
                    props: {
                        breadcrumbElement: {
                            name: 'Templates for approval',
                        },
                    },
                    meta: {
                        sideMenuSection: 'products',
                    },
                    children: [
                        {
                            name: 'SupplierTemplatesForApproval',
                            path: '/',
                            component: load('supplier/templates_for_approval/SupplierTemplatesForApprovalOverview'),
                        },
                        {
                            name: 'SupplierProductConfigurationPropositions',
                            path: ':productConfigurationId',
                            component: load('supplier/templates_for_approval/SupplierProductConfigurationPropositions'),
                            props: {
                                breadcrumbElement: {
                                    name: 'Configuration propositions',
                                },
                            },
                        },
                    ],
                },
                {
                    path: 'product-template-propagation',
                    name: 'ProductTemplatePropagation',
                    component: load('supplier/products/ProductTemplatePropagation'),
                    props: {
                        breadcrumbElement: {
                            name: 'Product Template Propagation',
                        },
                    },
                    meta: {
                        sideMenuSection: 'products',
                    },
                },
                {
                    path: 'commercial-products',
                    name: 'CommercialProducts',
                    component: load('supplier/SupplierTemplatesForApproval'),
                    props: {
                        breadcrumbElement: {
                            name: 'Commercial Products',
                        },
                    },
                    meta: {
                        sideMenuSection: 'products',
                    },
                    children: [
                        {
                            name: 'CommercialProductsView',
                            path: '/',
                            component: load('supplier/products/CommercialProducts'),
                        },
                        {
                            path: 'add',
                            name: 'CommercialProductAdd',
                            component: load('supplier/products/CommercialProductAddEdit'),
                            props: {
                                breadcrumbElement: {
                                    name: 'Add Commercial Product',
                                },
                            },
                        },
                        {
                            path: 'edit/:commercialProductId?',
                            name: 'CommercialProductEdit',
                            component: load('supplier/products/CommercialProductAddEdit'),
                            props: {
                                breadcrumbElement: {
                                    name: 'Edit Commercial Product',
                                },
                            },
                        },
                    ],
                },
            ],
        },
        {
            path: '/legacy',
            component: load('LegacyView'),
            props: {
                breadcrumbElement: {
                    name: 'Legacy integration',
                },
            },
            meta: {
                section: 'legacy',
            },
            children: [
                {
                    path: '',
                    component: load('legacy/LegacyOverview'),
                    name: 'LegacyView',
                    props: {
                        breadcrumbElement: {
                            name: 'Legacy integration',
                        },
                    },
                },
                {
                    path: 'products',
                    component: load('legacy/products/LegacyProductsView'),
                    children: [
                        {
                            name: 'LegacyProductsOverview',
                            path: '',
                            component: load('legacy/products/LegacyProductsOverview'),
                            props: {
                                breadcrumbElement: {
                                    name: 'Legacy products',
                                },
                            },
                        },
                    ],
                },
                {
                    path: 'attributes',
                    name: 'LegacyAttributesView',
                    component: load('legacy/attributes/LegacyAttributesView'),
                    props: {
                        breadcrumbElement: {
                            name: 'Legacy attributes',
                        },
                    },
                },
            ],
        },
        {
            path: '/agreements-management',
            component: load('AgreementsManagementView'),
            name: 'AgreementsManagementView',
            meta: {
                section: 'agreements_management',
            },
            props: {
                breadcrumbElement: {
                    name: 'Agreements Management',
                },
            },
        },
        {
            path: '/agreements',
            component: load('AgreementsView'),
            props: {
                breadcrumbElement: {
                    name: 'Agreements',
                },
            },
            meta: {
                section: 'agreements_management',
            },
            children: [
                {
                    path: '',
                    component: load('agreements/AgreementsOverview'),
                    name: 'AgreementsView',
                },
                {
                    path: 'add',
                    component: load('agreements/AgreementAdd'),
                    name: 'AgreementAdd',
                    meta: {
                        sidebarSize: 'mini',
                    },
                    props: {
                        breadcrumbElement: {
                            name: 'Add agreement',
                        },
                    },
                },
                {
                    path: 'edit/:agreementId',
                    component: load('agreements/AgreementAdd'),
                    name: 'AgreementEdit',
                    meta: {
                        sidebarSize: 'mini',
                    },
                    props: {
                        breadcrumbElement: {
                            name: 'Edit agreement',
                        },
                    },
                },
            ],
        },
        {
            path: '/pricing',
            component: load('PricingView'),
            props: {
                breadcrumbElement: {
                    name: 'Pricing',
                },
            },
            meta: {
                section: 'pricing',
            },
            children: [
                {
                    path: 'pricing-rules/:agreementId?/:templateId?',
                    component: load('pricing/PricingRules'),
                    name: 'PricingView',
                    props: {
                        breadcrumbElement: {
                            name: 'Pricing Rules',
                        },
                    },
                },
                {
                    path: 'pricing-rule-labels',
                    component: load('pricing/PricingRuleLabels'),
                    name: 'PricingRuleLabels',
                    props: {
                        breadcrumbElement: {
                            name: 'Pricing Rule Labels',
                        },
                    },
                },
                {
                    path: 'test-prices',
                    component: load('pricing/PricingTestPrices'),
                    name: 'PricingTestPrices',
                    props: {
                        breadcrumbElement: {
                            name: 'Test Prices',
                        },
                    },
                },
                {
                    path: 'pricing-rule-templates/:agreementId?',
                    component: load('pricing/PricingRuleTemplates'),
                    name: 'PricingRuleTemplates',
                    props: {
                        breadcrumbElement: {
                            name: 'Pricing Rule Templates',
                        },
                    },
                },
                {
                    path: 'pricing-rule-templates/:agreementId/:pricingRuleTemplateId/tasks',
                    name: 'TemplateTasksOverview',
                    component: load('pricing/TemplateTasksOverview'),
                    props: route => {
                        return {
                            ...route.params,
                            breadcrumbElement: {
                                name: 'Pricing Rule Template tasks',
                            },
                        }
                    },
                    children: [
                        {
                            path: 'logs',
                            name: 'TemplateLogs',
                            component: load('pricing/TemplateLogs'),
                            meta: {
                                sidebarSize: 'mini',
                                rightSideBar: true,
                            },
                            props: true,
                        },
                    ],
                },
                {
                    path: 'price-margins/:priceMarginId?',
                    component: load('pricing/PriceMargins'),
                    name: 'PriceMargins',
                    props: {
                        breadcrumbElement: {
                            name: 'Price Margins',
                        },
                    },
                },
                {
                    path: 'turnaround-strategy/:turnaroundStrategyId?',
                    component: load('pricing/TurnaroundStrategy'),
                    name: 'TurnaroundStrategy',
                    props: {
                        breadcrumbElement: {
                            name: 'Turnaround strategy',
                        },
                    },
                },
                {
                    path: 'turnaround-upsell/:turnaroundUpsellId?',
                    component: load('pricing/TurnaroundUpsell'),
                    name: 'TurnaroundUpsell',
                    props: {
                        breadcrumbElement: {
                            name: 'Turnaround upsell',
                        },
                    },
                },
                {
                    path: 'optimized-shipping/:optimizedShippingId?',
                    component: load('pricing/OptimizedShipping'),
                    name: 'OptimizedShipping',
                    props: {
                        breadcrumbElement: {
                            name: 'Optimized shipping',
                        },
                    },
                },
                {
                    path: 'service-levels',
                    component: load('pricing/OptimizedShippingServiceLevels'),
                    name: 'OptimizedShippingServiceLevels',
                    props: {
                        breadcrumbElement: {
                            name: 'Service Levels',
                        },
                    },
                },
            ],
        },
        {
            path: '/custom-carrier-costs',
            component: load('CustomCarrierCostsView'),
            meta: {
                section: 'agreements_management',
            },
            props: {
                breadcrumbElement: {
                    name: 'Custom Carrier Costs',
                },
            },
            children: [
                {
                    path: '',
                    component: load('custom_carrier_costs/CustomCarrierCostsOverview'),
                    name: 'CustomCarrierCostsOverview',
                },
            ],
        },
        {
            path: '/quotes',
            component: load('QuotesView'),
            props: {
                breadcrumbElement: {
                    name: 'Quotes',
                },
            },
            meta: {
                section: 'quotes',
            },
            children: [
                {
                    path: '/',
                    name: 'QuotesOverview',
                    redirect: {
                        name: 'QuotesView',
                        params: {
                            status: 'awaiting-quotation',
                        },
                    },
                },
                {
                    path: 'status/:status?/:quoteAssigneeId?',
                    component: load('quotes/QuotesOverview'),
                    name: 'QuotesView',
                    props: true,
                },
                {
                    path: ':supplierQuoteId',
                    component: load('quotes/QuoteDetails'),
                    name: 'QuoteDetails',
                    meta: {
                        sidebarSize: 'mini',
                    },
                    props: {
                        breadcrumbElement: {
                            name: 'Quote Details',
                        },
                    },
                },
            ],
        },
        {
            path: '/supplier-integrations',
            component: load('SupplierIntegrationsView'),
            props: {
                breadcrumbElement: {
                    name: 'Supplier Integrations',
                },
            },
            meta: {
                section: 'suppliers',
                sidebarSize: 'mini',
            },
            children: [
                {
                    path: '',
                    component: load('supplier_integrations/SupplierIntegrationsOverview'),
                    name: 'SupplierIntegrationsView',
                },
                {
                    path: ':supplierIntegrationConfigId',
                    component: load('supplier_integrations/SupplierIntegrationDetail'),
                    meta: {
                        sidebarSize: 'mini',
                    },
                    props: {
                        breadcrumbElement: {
                            name: '...',
                        },
                    },
                    children: [
                        {
                            path: '',
                            component: load('supplier_integrations/SupplierIntegrationAttributeMapping'),
                            name: 'SupplierIntegrationDetail',
                            meta: {
                                sidebarSize: 'mini',
                            },
                            props: {
                                breadcrumbElement: {
                                    name: 'Attribute mapping',
                                },
                            },
                        },
                        {
                            path: 'product-review',
                            component: load('supplier_integrations/SupplierIntegrationProductReview'),
                            name: 'SupplierIntegrationProductReview',
                            meta: {
                                sidebarSize: 'mini',
                            },
                            props: {
                                breadcrumbElement: {
                                    name: 'Product review',
                                },
                            },
                        },
                    ],
                },
            ],
        },
        {
            path: '/logistics',
            component: load('LogisticsView'),
            name: 'LogisticsOverview',
            meta: {
                section: 'logistics',
            },
            props: {
                breadcrumbElement: {
                    name: 'Logistics',
                },
            },
        },
        {
            path: '/extra-days',
            component: load('ExtraDaysView'),
            meta: {
                section: 'logistics',
            },
            props: {
                breadcrumbElement: {
                    name: 'Extra days',
                },
            },
            children: [
                {
                    path: '',
                    component: load('extra_days/ExtraDaysOverview'),
                    name: 'ExtraDaysOverview',
                },
            ],
        },
        {
            path: '/hs-codes',
            component: load('HsCodesView'),
            meta: {
                section: 'logistics',
            },
            props: {
                breadcrumbElement: {
                    name: 'Hs Codes',
                },
            },
            children: [
                {
                    path: '',
                    component: load('hs_codes/HsCodesOverview'),
                    name: 'HsCodesOverview',
                },
                {
                    path: ':productConfigurationTemplateId',
                    component: load('hs_codes/HsCodesProductTemplateEdit'),
                    name: 'HsCodesProductTemplateEdit',
                },
            ],
        },
        {
            path: '/factors',
            component: load('FactorsView'),
            meta: {
                section: 'suppliers',
            },
            props: {
                breadcrumbElement: {
                    name: 'Factors',
                },
            },
            children: [
                {
                    path: '',
                    component: load('factors/FactorsOverview'),
                    name: 'FactorsOverview',
                },
            ],
        },
        {
            path: '/artwork-files',
            component: load('ArtworkFilesView'),
            meta: {
                section: 'suppliers',
            },
            props: {
                breadcrumbElement: {
                    name: 'Artwork',
                },
            },
            children: [
                {
                    path: '',
                    component: load('artwork_files/ArtworkFilesOverview'),
                    name: 'ArtworkFilesOverview',
                },
            ],
        },
        {
            path: '/products',
            component: load('ProductsView'),
            meta: {
                section: 'suppliers',
            },
            props: {
                breadcrumbElement: {
                    name: 'Products',
                },
            },
            children: [
                {
                    path: '',
                    component: load('products/ProductsForApprovalOverview'),
                    name: 'ProductsForApprovalOverview',
                },
            ],
        },
        {
            path: '/complaints',
            component: load('ComplaintsGlobalView'),
            meta: {
                section: 'complaints_global',
            },
            props: {
                breadcrumbElement: {
                    name: 'Complaints',
                },
            },
            children: [
                {
                    path: '',
                    name: 'ComplaintsGlobalView',
                    component: load('complaints/ComplaintsGlobalLanding'),
                    props: true,
                },
                {
                    path: ':complaintSection/:complaintStatus',
                    component: load('complaints/ComplaintsGlobalOverview'),
                    name: 'ComplaintsGlobalOverview',
                    props: true,
                    children: [
                        {
                            path: 'supplier/:complaintSupplierId/complaint/:complaintId',
                            name: 'ComplaintGlobalDetail',
                            component: load('supplier/complaints/ComplaintDetail'),
                            meta: {
                                sidebarSize: 'mini',
                                rightSideBar: true,
                                sideMenuSection: 'complaints',
                            },
                            props: true,
                        },
                    ],
                },
            ],
        },
        {
            path: '/orders',
            component: load('OrdersView'),
            props: {
                breadcrumbElement: {
                    name: 'Orders',
                },
            },
            meta: {
                section: 'orders',
            },
            children: [
                {
                    path: '',
                    component: load('orders/OrdersLanding'),
                    name: 'OrdersView',
                },
                {
                    path: 'overview',
                    component: load('orders/OrderListView'),
                    props: {
                        breadcrumbElement: {
                            name: 'Overview',
                        },
                    },
                    children: [
                        {
                            path: ':orderLineOverviewType?',
                            component: load('orders/OrderListOverview'),
                            name: 'OrderListOverview',
                            meta: {
                                sidebarSize: 'mini',
                                sideMenuSection: 'orders',
                            },
                            props: true,
                            children: [
                                {
                                    path: ':selectedSupplierId/orderline/:orderId/:orderLineId',
                                    name: 'VirtualSupplierOrderLineDetail',
                                    component: load('supplier/orderlines/OrderLineDetail'),
                                    meta: {
                                        sidebarSize: 'mini',
                                        rightSideBar: true,
                                        sideMenuSection: 'orders',
                                    },
                                    props: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'submissions',
                    component: load('orders/OrderSubmissionsLogsView'),
                    props: {
                        breadcrumbElement: {
                            name: 'Submissions',
                        },
                    },
                    children: [
                        {
                            path: '',
                            component: load('orders/OrderSubmissionsLogsOverview'),
                            name: 'OrderSubmissionsLogsView',
                            meta: {
                                sidebarSize: 'mini',
                            },
                        },
                    ],
                },
                {
                    path: 'transport-settings',
                    component: load('orders/OrderSubmissionsTransportSettingsView'),
                    props: {
                        breadcrumbElement: {
                            name: 'Transport settings',
                        },
                    },
                    children: [
                        {
                            path: '',
                            component: load('orders/OrderSubmissionsTransportSettingsOverview'),
                            name: 'OrderSubmissionsTransportSettingsView',
                            meta: {
                                sidebarSize: 'mini',
                            },
                        },
                        {
                            path: ':transportSettingId?',
                            component: load('orders/OrderSubmissionsTransportSettingsDetail'),
                            name: 'OrderSubmissionsTransportSettingsDetail',
                            meta: {
                                sidebarSize: 'mini',
                            },
                            children: [
                                {
                                    path: 'edit',
                                    component: load('orders/OrderSubmissionsTransportSettingsEdit'),
                                    name: 'OrderSubmissionsTransportSettingsEdit',
                                    meta: {
                                        sidebarSize: 'mini',
                                    },
                                    props: {
                                        breadcrumbElement: {
                                            name: 'Edit',
                                        },
                                    },
                                },
                                {
                                    path: 'new',
                                    component: load('orders/OrderSubmissionsTransportSettingsEdit'),
                                    name: 'OrderSubmissionsTransportSettingsNew',
                                    meta: {
                                        sidebarSize: 'mini',
                                        type: 'new',
                                    },
                                    props: {
                                        breadcrumbElement: {
                                            name: 'New',
                                        },
                                    },
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            path: '/holidays',
            meta: {
                section: 'global_holidays',
            },
            name: 'GlobalHolidaysView',
            component: load('GlobalHolidaysView'),
            props: {
                breadcrumbElement: {
                    name: 'Global holidays',
                },
            },
        },
        {
            path: '/proposal/:token',
            meta: {
                section: 'quotes_proposal',
                unguarded: true,
            },
            component: load('QuoteProposalView'),
            children: [
                {
                    path: '',
                    alias: 'upload',
                    component: load('quotes/QuoteProposalUpload'),
                    name: 'QuoteProposalView',
                },
            ],
        },
        {
            path: '/access-control',
            name: 'AccessControlView',
            meta: {
                section: 'access-control',
            },
            redirect: '/access-control/accounts',
            component: load('AccessControlView'),
            props: {
                breadcrumbElement: {
                    name: 'Access Control',
                },
            },
            children: [
                {
                    name: 'AccessControlOverview',
                    path: 'accounts/:organisationId([a-f0-9]{8}-?[a-f0-9]{4}-?4[a-f0-9]{3}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12})?',
                    component: load('access_control/AccessControlOverview'),
                    breadcrumbElement: {
                        name: 'Access Control Organisation',
                    },
                    props: true,
                },
                {
                    path: 'permissions',
                    component: load('PermissionsView'),
                    props: {
                        breadcrumbElement: {
                            name: 'Permissions',
                        },
                    },
                    children: [
                        {
                            path: '',
                            name: 'AccessControlPermissions',
                            component: load('access_control/AccessControlPermissionsOverview'),
                        },
                        {
                            path: 'add',
                            name: 'AccessControlAddPermission',
                            component: load('access_control/AddEditPermission'),
                            props: {
                                breadcrumbElement: {
                                    name: 'Add',
                                },
                            },
                        },
                        {
                            path: 'edit/:permissionId?',
                            name: 'AccessControlEditPermission',
                            component: load('access_control/AddEditPermission'),
                            props: {
                                breadcrumbElement: {
                                    name: 'Edit',
                                },
                            },
                        },
                    ],
                },
                {
                    path: 'roles',
                    component: load('RolesView'),
                    props: {
                        breadcrumbElement: {
                            name: 'Roles',
                        },
                    },
                    children: [
                        {
                            path: '',
                            name: 'AccessControlRoles',
                            component: load('access_control/AccessControlRolesOverview'),
                        },
                        {
                            path: 'add',
                            name: 'AccessControlAddRole',
                            component: load('access_control/AddEditRole'),
                            props: {
                                breadcrumbElement: {
                                    name: 'Add',
                                },
                            },
                        },
                        {
                            path: 'edit/:roleId?',
                            name: 'AccessControlEditRole',
                            component: load('access_control/AddEditRole'),
                            props: {
                                breadcrumbElement: {
                                    name: 'Edit',
                                },
                            },
                        },
                    ],
                },
            ],
        },
        {
            path: '/product-content-management',
            meta: {
                section: 'product-content-management',
            },
            component: load('ProductContentCategoriesView'),
            props: {
                breadcrumbElement: {
                    name: 'Content management',
                },
            },
            children: [
                {
                    name: 'ProductContentCategoriesView',
                    path: 'categories',
                    component: load('product_content/categories/ProductContentCategoriesView'),
                    props: true,
                    breadcrumbElement: {
                        name: 'Categories',
                    },
                    children: [
                        {
                            path: '',
                            name: 'ProductContentCategoriesView',
                            component: load('product_content/categories/ProductContentCategoriesOverview'),
                            props: true,
                            breadcrumbElement: {
                                name: 'Categories',
                            },
                        },
                        {
                            path: 'add',
                            name: 'ProductContentCategoryAdd',
                            component: load('product_content/categories/ProductContentCategoryDetail'),
                            props: true,
                            breadcrumbElement: {
                                name: 'Categories',
                            },
                        },
                        {
                            path: ':categoryId',
                            name: 'ProductContentCategoryDetail',
                            component: load('product_content/categories/ProductContentCategoryDetail'),
                            props: true,
                            breadcrumbElement: {
                                name: 'Categories',
                            },
                        },
                    ],
                },
            ],
        },
        {
            path: '/carriers',
            meta: {
                section: 'logistics',
                sidebarSize: 'mini',
            },
            component: load('CarriersView'),
            props: {
                breadcrumbElement: {
                    name: 'Carriers',
                },
            },
            children: [
                {
                    name: 'CarriersOverview',
                    path: '',
                    component: load('carriers/CarriersOverview'),
                    breadcrumbElement: {
                        name: 'Carriers',
                    },
                    meta: {
                        sidebarSize: 'mini',
                    },
                },
                {
                    path: ':carrierId',
                    component: load('carriers/CarrierView'),
                    children: [
                        {
                            name: 'CarrierView',
                            path: '',
                            component: load('carriers/CarrierDetail'),
                        },
                        {
                            name: 'CarrierEdit',
                            path: 'edit',
                            component: load('carriers/CarrierEdit'),
                        },
                        {
                            path: ':serviceLevelId',
                            component: load('carriers/ServiceLevelView'),
                            children: [
                                {
                                    path: '',
                                    name: 'CarrierServiceLevelView',
                                    component: load('carriers/ServiceLevelDetail'),
                                    meta: {
                                        sidebarSize: 'mini',
                                    },
                                },
                                {
                                    path: ':serviceLevelConfigurationId',
                                    component: load('carriers/ServiceLevelConfigurationView'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'CarrierServiceLevelConfigurationView',
                                            component: load('carriers/ServiceLevelConfigurationDetail'),
                                        },
                                    ],
                                    meta: {
                                        sidebarSize: 'mini',
                                    },
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            path: '/artwork-management',
            meta: {
                section: 'artwork_management',
            },
            component: load('ArtworkManagementView'),
            props: {
                breadcrumbElement: {
                    name: 'Artwork Management',
                },
            },
            children: [
                {
                    path: '',
                    name: 'ArtworkManagementView',
                    component: load('artwork_management/ArtworkManagementOverview'),
                    meta: {
                        sidebarSize: 'mini',
                    },
                },
                {
                    path: ':productConfigurationId',
                    name: 'ArtworkManagementConfigurations',
                    component: load('artwork_management/configurations/ArtworkManagementConfigurations'),
                    meta: {
                        sidebarSize: 'mini',
                    },
                    children: [
                        {
                            name: 'ArtworkManagementStartStep',
                            path: 'start',
                            component: load('artwork_management/configurations/StartStep'),
                            props: true,
                        },
                        {
                            name: 'ArtworkManagementUniqueCombinations',
                            path: 'unique-combinations',
                            component: load('artwork_management/configurations/UniqueCombinations'),
                            props: true,
                        },
                        {
                            name: 'ArtworkManagementBasicArtworkConfig',
                            path: 'basic-artwork-config',
                            component: load('artwork_management/configurations/BasicArtworkConfig'),
                            props: true,
                        },
                        {
                            name: 'ArtworkManagementManualCheckConfig',
                            path: 'manual-check-config',
                            component: load('artwork_management/configurations/ManualCheckConfig'),
                            props: true,
                        },
                        {
                            name: 'ArtworkManagementAutoCheckConfig',
                            path: 'auto-check-config',
                            component: load('artwork_management/configurations/AutoCheckConfig'),
                            props: true,
                        },
                        {
                            name: 'ArtworkManagementConfirmation',
                            path: 'confirmation',
                            component: load('artwork_management/configurations/Confirmation'),
                            props: true,
                        },
                    ],
                },
            ],
        },
    ],
})
