export const ADD_TASK = 'ADD_TASK'
export const CLEAR_TASKS = 'CLEAR_TASKS'

const doRetry = (dispatch, result, task) => {
    task.attemptsLeft--
    if (result === null && task.attemptsLeft > 0) {
        setTimeout(() => {
            pollTask(dispatch, task)
        }, task.interval)
        return
    }
    if (task.attemptsLeft === 0) {
        task.status = 'FAILED'
        throw new Error('Max attempts exceeded', task)
    }

    task.status = 'COMPLETED'
    dispatch('notifications/updateNotification', { type: 'tasks', notification: task }, {
        root: true,
    })
    task.onComplete(result)
}

const pollTask = (dispatch, task) => {
    task.onPoll().then(task.handleResult).then(result => {
        doRetry(dispatch, result, task)
    }).catch((rejected) => {
        if (!task.retryOnFail) {
            task.status = 'FAILED'
            throw rejected
        }
        dispatch('notifications/updateNotification', { type: 'tasks', notification: task }, {
            root: true,
        })
        doRetry(dispatch, null, task)
    })
}

export default {
    namespaced: true,

    state: {
        tasks: [],
        tasksIncrementer: 0,
    },

    mutations: {
        [ADD_TASK] (state, task) {
            state.tasks.push(task)
            state.tasksIncrementer++
        },

        [ CLEAR_TASKS ] (state) {
            this._vm.$set(state, 'tasks', [])
        },
    },

    actions: {
        addTask ({ dispatch, commit }, task) {
            task.attemptsLeft = task.attempts
            task.status = 'RUNNING'
            commit(ADD_TASK, task)
            dispatch('notifications/addNotificationNonPersistent', { type: 'tasks', notification: task }, {
                root: true,
            })
            pollTask(dispatch, task)
        },
        clearTasks ({ commit }) {
            commit(CLEAR_TASKS)
        },
    },
    getters: {
        getRunningTasksCount (state) {
            return state.tasks.filter(({ status }) => status === 'RUNNING').length
        },
    },
}
