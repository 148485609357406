<template>
    <div>
        <mercur-item data-test="pricingSidebarPricingRuleTemplatesLink" :to="getRouteForName('PricingRuleTemplates')">
            <i class="fas fa-chevron-right"></i><span>Pricing Rule Templates</span>
        </mercur-item>
        <mercur-item data-test="pricingSidebarTestPricesLink" :to="getRouteForName('PricingTestPrices')">
            <i class="fas fa-chevron-right"></i><span>Test Prices</span>
        </mercur-item>
        <mercur-item data-test="pricingSidebarPricingRuleLabelsLink" :to="getRouteForName('PricingRuleLabels')">
            <i class="fas fa-chevron-right"></i><span>Pricing Rule Labels</span>
        </mercur-item>
        <mercur-item data-test="pricingSidebarRulesLink" :to="getRouteForName('PricingView')">
            <i class="fas fa-chevron-right"></i><span>Pricing Rules</span>
        </mercur-item>
        <mercur-item data-test="pricingSidebarTurnaroundStrategyLink" :to="getRouteForName('TurnaroundStrategy')">
            <i class="fas fa-chevron-right"></i><span>Turnaround Strategy</span>
        </mercur-item>
        <mercur-item data-test="pricingSidebarTurnaroundUpsellLink" :to="getRouteForName('TurnaroundUpsell')">
            <i class="fas fa-chevron-right"></i><span>Turnaround Upsell</span>
        </mercur-item>
        <mercur-item data-test="pricingSidebarMarginsLink" :to="getRouteForName('PriceMargins')">
            <i class="fas fa-chevron-right"></i><span>Price Margins</span>
        </mercur-item>
        <mercur-item data-test="pricingSidebarOptimizeShippingLink" :to="getRouteForName('OptimizedShipping')">
            <i class="fas fa-chevron-right"></i><span>Optimized Shipping</span>
        </mercur-item>
        <mercur-item data-test="pricingSidebarServiceLevelLink" :to="getRouteForName('OptimizedShippingServiceLevels')">
            <i class="fas fa-chevron-right"></i><span>Service Level Mappings</span>
        </mercur-item>
    </div>
</template>

<script>

export default {
    name: 'PricingSideMenu',
    methods: {
        getRouteForName (section, params) {
            return this.$router.resolve({
                name: section,
                params: {
                    ...params,
                },
            }).href
        },
    },
}
</script>
