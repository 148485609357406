import { SET_CURRENCY_CONVERSION_TABLE, SET_AVAILABLE_CURRENCIES } from '../mutation-types'
import CONFIG from '@root/config'
import currencyToSymbolMap from 'currency-symbol-map/map'

export default {
    namespaced: true,

    state: {
        conversionTable: [],
        availableCurrencies: {},
    },

    mutations: {
        [ SET_CURRENCY_CONVERSION_TABLE ] (state, table) {
            this._vm.$set(state, 'conversionTable', table)
        },
        [ SET_AVAILABLE_CURRENCIES ] (state, availableCurrencies) {
            this._vm.$set(state, 'availableCurrencies', availableCurrencies)
        },
    },

    actions: {
        getCurrencyConversionTable ({ commit, state }) {
            return this._vm.$api.get(CONFIG.API.ROUTES.FINANCE.CURRENCY.GET_TABLE).then(({ data }) => {
                commit(SET_CURRENCY_CONVERSION_TABLE, data.data.rates)
                const availableCurrenciesMap = data.data.rates.reduce((availableCurrencies, conversionEntry) => {
                    const currencyCode = conversionEntry.currencyFrom
                    if (!availableCurrencies[currencyCode]) {
                        availableCurrencies[currencyCode] = {
                            code: currencyCode,
                            sign: currencyToSymbolMap[currencyCode] || currencyCode,
                        }
                    }

                    return availableCurrencies
                }, {})

                const availableCurrencies = Object.entries(availableCurrenciesMap)
                availableCurrencies.sort((a, b) => a[0].localeCompare(b[0]))

                commit(SET_AVAILABLE_CURRENCIES, Object.fromEntries(availableCurrencies))

                return data.data.rates
            })
        },
    },
    getters: {
        convertValueObjectTo (state) {
            return (value, destinationCurrencyCode) => {
                if (!value) {
                    return value
                }
                const rate = state.conversionTable.find(({ currencyFrom, currencyTo }) => currencyFrom === value.currency && currencyTo === destinationCurrencyCode)
                if (rate) {
                    return {
                        value: value.value * rate.rate,
                        currency: destinationCurrencyCode,
                    }
                }
                return value
            }
        },
    },
}
