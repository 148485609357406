<template>
    <div class="breadcrumbs">
        <i class="fas fa-home text-orange" />
        <i class="fas fa-angle-right mx-2 text-grey" />
        <router-link :to="{name: 'HomeView'}" class="breadcrumbs__item">
            <span class="breadcrumbs__item-text">Start</span>
        </router-link>
        <span v-for="(breadCrumb, key) in breadCrumbs" :key="key">
            <i class="fas fa-angle-right mx-2 text-grey" />
            <router-link :to="getRouteByName(breadCrumb.viewName)" :is="key < breadCrumbs.length - 1 ? 'router-link' : 'span'" class="breadcrumbs__item">
                <span data-test="breadcrumbText" class="breadcrumbs__item-text">{{breadCrumb.prettyName}}</span>
            </router-link>
        </span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Breadcrumbs',
    props: ['routeview'],

    data () {
        return {
            breadCrumbs: [],
        }
    },
    computed: {
        ...mapGetters('loading', [ 'getPendingJobs' ]),
    },

    methods: {
        getRouteByName (name) {
            return {
                name,
                params: {
                    ...this.$route.params,
                    supplierId: this.supplierId,
                },
            }
        },
        updateBreadcrumbs () {
            this.$nextTick(() => {
                let breadCrumbs = []
                let breadcrumbRef = this.routeview
                this.breadCrumbs = []
                while (breadcrumbRef) {
                    let breadcrumbElement = breadcrumbRef.breadcrumbElement || breadcrumbRef.$attrs.breadcrumbElement
                    if (breadcrumbElement) {
                        breadCrumbs.push({
                            prettyName: breadcrumbElement.name,
                            viewName: breadcrumbRef.$options.name,
                        })
                    }

                    if (breadcrumbRef.$refs && breadcrumbRef.$refs.routeview) {
                        breadcrumbRef = breadcrumbRef.$refs.routeview
                    } else {
                        breadcrumbRef = false
                    }
                }
                this.breadCrumbs = breadCrumbs

                this.updateTitleTag()
            })
        },
        updateTitleTag () {
            let titleTagText = 'Supplier Central'

            if (this.breadCrumbs.length) {
                titleTagText = this.breadCrumbs.map(crumb => crumb.prettyName).flat().reverse().join(' - ') + ' - ' + titleTagText
            }

            document.querySelector('title').innerHTML = titleTagText
        },
    },

    created () {
        this.$watch(() => {
            return this.$route.fullPath + JSON.stringify(this.getPendingJobs)
        }, this.updateBreadcrumbs)
    },
    watch: {
        routeview () {
            this.updateBreadcrumbs()
        },
    },
}
</script>

<style lang="scss" scoped>
    $color: #444;
    .breadcrumbs {
        padding-bottom: 5px;
        padding-left: 30px;
        .fas {
            font-size: 12px !important;
        }
        &__item {
            &:hover {
                color: $color;
            }
        }
        &__item-text {
            font-size: 12px;
            color: $color;
            letter-spacing: 0;
        }
    }
</style>
