<template>
    <div>
        <mercur-item :to="getRouteForName('AccessControlOverview')" v-if="isAllowedTo('listAccounts')">
            <i class="fas fa-user-circle"></i><span>Accounts</span>
        </mercur-item>
        <mercur-item :to="getRouteForName('AccessControlRoles')" v-if="isAllowedTo('listRoles')">
            <i class="fas fa-hat-cowboy"></i><span>Roles</span>
        </mercur-item>
        <mercur-item :to="getRouteForName('AccessControlPermissions')" v-if="isAllowedTo('listPermissions')">
            <i class="fas fa-layer-group"></i><span>Permissions</span>
        </mercur-item>
    </div>
</template>

<script>
export default {
    name: 'AccessControlSideMenu',
    methods: {
        getRouteForName (section, params) {
            return this.$router.resolve({
                name: section,
                params: {
                    ...params,
                },
            }).href
        },
    },
}
</script>
