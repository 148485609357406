import VuexPersistence from 'vuex-persist'
import modules from './modules'

export default {
    modules,
    plugins: [new VuexPersistence({
        reducer: (state) => ({
            auth: {
                allowedActions: state.auth.allowedActions,
            },
            notifications: {
                notifications: state.notifications.notifications,
            },
        }),
    }).plugin],
}
