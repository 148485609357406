import { SET_PRODUCT_ATTRIBUTES, SET_PRODUCT_ATTRIBUTE_POSTFIXES } from '@/store/mutation-types'
import CONFIG from '@root/config'

export default {
    namespaced: true,

    state: {
        productAttributes: {},
        postfixes: [],
    },

    mutations: {
        [ SET_PRODUCT_ATTRIBUTES ] (state, productAttributes) {
            this._vm.$set(state, 'productAttributes', productAttributes)
        },
        [ SET_PRODUCT_ATTRIBUTE_POSTFIXES ] (state, postfixes) {
            this._vm.$set(state, 'postfixes', postfixes)
        },
    },

    actions: {
        fetch ({ commit }, { url, mutation }) {
            return this.$api.get(url).then(({ data }) => {
                commit(mutation, data)
            })
        },

        fetchProductAttributes ({ dispatch, commit, state }) {
            dispatch('loading/addJob', SET_PRODUCT_ATTRIBUTES, {
                root: true,
            })
            return new Promise((resolve, reject) => {
                const url = CONFIG.API.ROUTES.PRODUCTS.GENERATION.ATTRIBUTES.GET
                this._vm.$api.get(url).then(({ data }) => {
                    commit(SET_PRODUCT_ATTRIBUTES, data.data)
                    resolve(data)
                }).catch((err) => {
                    if (CONFIG.DEBUG) {
                        console.error(err)
                    }
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', SET_PRODUCT_ATTRIBUTES, {
                        root: true,
                    })
                })
            })
        },

        fetchPostfixes ({ dispatch, commit }) {
            dispatch('loading/addJob', SET_PRODUCT_ATTRIBUTE_POSTFIXES, {
                root: true,
            })
            return new Promise((resolve, reject) => {
                const url = CONFIG.API.ROUTES.PRODUCTS.GENERATION.ATTRIBUTES.LIST_POSTFIXES
                this._vm.$api.post(url).then(({ data }) => {
                    commit(SET_PRODUCT_ATTRIBUTE_POSTFIXES, data.data)
                    resolve(data)
                }).catch((err) => {
                    if (CONFIG.DEBUG) {
                        console.error(err)
                    }
                    reject(err)
                }).finally(() => {
                    dispatch('loading/finishJob', SET_PRODUCT_ATTRIBUTE_POSTFIXES, {
                        root: true,
                    })
                })
            })
        },
    },
    getters: {
        getProductAttributes (state) {
            return state.productAttributes
        },
    },
}
